import React, {useEffect, useState} from 'react';
import {Link, RouteComponentProps, RouteProps, useParams} from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { CourseCardComponent } from '../../components/course-card';
import { data } from './data'
import './category-sessions.scss';
import {sessionService} from "../../services/sessions.service";
import CategoryDetailLoader from "./category-detail-loader";

interface IProps {
    data: any;
}

export const CategorySessions: React.FC<IProps> = (props) => {
    let params : any = useParams();
    const [sessions, setSessions] = useState([]);
    const [category, setCategory] = useState({
        name : '',
        full_image : ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let sessionId = params.id;

        sessionService.categorySessions(sessionId).then(({data} : any) => {
            if (data.status_code == 200){
                setSessions(data.data.session)
                setCategory(data.data.category)
                setLoading(false)
            }
            console.log(data);
        })
    }, []);

console.log(props)
    return (
        <Layout>
            {loading ? <CategoryDetailLoader /> : (
            <div className="rt-content rt-category-details">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to="/train-categores" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>{category.name}</h2>
                    </div>
                </div>
                <div className="rt-categorycontentarea">
                    <div className="rt-banner">
                        <figure className="rt-bannerimg">
                            <img src={category.full_image} alt="" />
                            <figcaption className="rt-bannercontent">
                                <h2>{category.name}</h2>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="rt-courses">
                        {sessions.map((d: any) => (
                            <CourseCardComponent
                                data={d}
                            />
                        ))}
                    </div>
                </div>
            </div>)}
        </Layout>
    );
};