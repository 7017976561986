import React from 'react';
import ContentLoader from "react-content-loader";
import {data} from "./data";
import {CategoryCardComponent} from "../../components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import DashboardSessionComponent from "../../components/dashboard/session.component";
import AddedSessionLoader from "../organize-training/added-sessions-loader";

const DashboardLoader = (props:any) => {
    return ( <div className="rt-content rt-dashboard"><div className="rt-statandcategoryarea">
        <div className="rt-statistics">
            <div className="rt-statistic">
                <div className="rt-content">
                    <ContentLoader
                        speed={2}
                        width={150}
                        height={150}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="50" height="50" />
                        <rect x="0" y="60" rx="8" ry="8" width="100" height="40" />
                        <rect x="0" y="110" rx="8" ry="8" width="150" height="40" />

                    </ContentLoader>
                </div>
                <div className="rt-iconbox">
                    <i className="icon-football"></i>
                </div>
            </div>
            <div className="rt-statistic">
                <div className="rt-content">
                    <ContentLoader
                        speed={2}
                        width={150}
                        height={150}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="50" height="50" />
                        <rect x="0" y="60" rx="8" ry="8" width="100" height="40" />
                        <rect x="0" y="110" rx="8" ry="8" width="150" height="40" />

                    </ContentLoader>
                </div>
                <div className="rt-iconbox">
                    <i className="icon-tropy"></i>
                </div>
            </div>
        </div>
        <div className="rt-categores">
            {data.map((d: any) => (
                <CategoryCardComponent
                    data={d}
                    link={true}
                />
            ))}
        </div>
        <div className="rt-schedule">
            <div className="rt-title">
                <h2>Schedule</h2>
            </div>
            <FullCalendar
                plugins={[ dayGridPlugin,interactionPlugin ]}
                initialView="dayGridMonth"
            />
        </div>
    </div>
        <div className="rt-sessionslistarea">
            <div className="rt-sessionslistholder">
                <div className="rt-title">
                    <h2>TODAY’S SESSIONS</h2>
                </div>
                <div className="rt-sessionslist">
                    <ContentLoader
                        speed={2}
                        backgroundColor="#f3f3f3"
                        height={250}
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="60" height="60" />
                        <rect x="70" y="10" rx="8" ry="8" width="120" height="20" />
                        <rect x="70" y="35" rx="8" ry="8" width="200" height="20" />

                        <rect x="0" y="75" rx="8" ry="8" width="60" height="60" />
                        <rect x="70" y="85" rx="8" ry="8" width="120" height="20" />
                        <rect x="70" y="110" rx="8" ry="8" width="200" height="20" />

                        <rect x="0" y="150" rx="8" ry="8" width="60" height="60" />
                        <rect x="70" y="160" rx="8" ry="8" width="120" height="20" />
                        <rect x="70" y="185" rx="8" ry="8" width="200" height="20" />

                    </ContentLoader>
                </div>
                <div className="rt-title">
                    <h2>UPCOMING SESSIONS</h2>
                </div>
                <div className="rt-sessionslist">
                    <ContentLoader
                        speed={2}
                        backgroundColor="#f3f3f3"
                        height={250}
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="60" height="60" />
                        <rect x="70" y="10" rx="8" ry="8" width="120" height="20" />
                        <rect x="70" y="35" rx="8" ry="8" width="200" height="20" />

                        <rect x="0" y="75" rx="8" ry="8" width="60" height="60" />
                        <rect x="70" y="85" rx="8" ry="8" width="120" height="20" />
                        <rect x="70" y="110" rx="8" ry="8" width="200" height="20" />

                        <rect x="0" y="150" rx="8" ry="8" width="60" height="60" />
                        <rect x="70" y="160" rx="8" ry="8" width="120" height="20" />
                        <rect x="70" y="185" rx="8" ry="8" width="200" height="20" />
                    </ContentLoader>
                </div>
            </div>
        </div></div>
    );
};

export default DashboardLoader;
