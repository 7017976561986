import { images } from "../../assets/image";

export const data = [
    {
        full_image: images.categoryimg01,
        name: "ORGANIZE",
        name1: "MY TRAINING",
        link: "/organize-training"
    },
    {
        full_image: images.categoryimg02,
        name: "JUST TRAIN",
        link: "/train-categores"
    },
    {
        full_image: images.categoryimg03,
        name: "LEARN",
        link: "/learn-categores"
    }
]