import React from 'react';
import ContentLoader from "react-content-loader";

const SelectCategoryLoader = (props:any) => {
    return (
        <ContentLoader
            speed={2}
            width={1561}
            height={1000}
            style={{marginTop:'-120px'}}
            viewBox="0 0 1267 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#effeb4"
            {...props}
        >
            <rect x="0" y="0" rx="8" ry="8" width="300" height="150" />
            <rect x="0" y="160" rx="8" ry="8" width="300" height="150" />
            <rect x="0" y="320" rx="8" ry="8" width="300" height="150" />

        </ContentLoader>
    );
};

export default SelectCategoryLoader;
