import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import ProgressBar from 'react-bootstrap/ProgressBar'
import './challenges-detail.scss';
import {leaderboardService} from "../../services/leaderboard.service";
import {ASSET_ENDPOINT, ASSET_ENDPOINT_LIVE} from "../../environment";

interface IProps {
    data: any;
}

export const ChallengesDetails: React.FC<IProps> = (props) => {

    const [challenge, setChallenge] = useState({
        id:0,
        title:'',
        full_image:'',
        full_banner:'',
        session_required:0,
        description:'',
        formatted_start:'',
        formatted_end:'',
        category:{
            name:''
        }
    });

    const [challengeProgress, setChallengeProgress] = useState({
        progress:'',
        required_sessions:''
    });
    const [joined, setJoined] = useState(0);
    const [diffInDays, setDiffInDays] = useState(0);
    const [lastThree, setLastThree] = useState([]);

    let params : any = useParams();
    useEffect(() => {
        console.log(params)
        getChallengeDetails(params.id)

    }, []);

    let joinChallenge = (id:number) => {
        leaderboardService.joinChallenge(id).then(({data} : any) => {
            if (data.status_code == 200){
                getChallengeDetails(id)
            }
        })
    }

    let getChallengeDetails = (id : number) => {
        leaderboardService.challengeDetail(id).then(({data}:any) => {
            if (data.status_code == 200){
                setChallenge(data.data.challange)
                if (data.data.challenge_progress) {
                    setChallengeProgress(data.data.challenge_progress)
                }
                setLastThree(data.data.last_three_users)
                setDiffInDays(data.data.diffInDay)
                setJoined(data.data.joined_users)
            }
        })
    }
    return (
        <Layout>
            <div className="rt-content rt-challenges-details">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to="/challenges" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>ALL CHALLENGES</h2>
                    </div>
                </div>
                <div className="rt-coursecontentarea">
                    <div className="rt-banner" style={{background: `url(${challenge.full_banner})`}}>
                        <div className="rt-bannercontent">
                            <div className="rt-bannercontent-holder">
                                <span className="rt-time">
                                    ACTIVE . {diffInDays} DAYS LEFT
                                </span>
                                <h2>{challenge.title} - {challenge.category.name}</h2>
                                <div className="rt-description">
                                    <p>You have completed {challengeProgress.progress} sessions</p>
                                </div>
                            </div>
                            <div className="rt-rightbox">
                                <button type="button" className="rt-btn rt-statsbtn" onClick={e => joinChallenge(challenge.id)} disabled={joined == 1 ? true : false}>{joined ? 'JOINED' : 'JOIN CHALLENGE'}</button>
                            </div>
                        </div>
                    </div>
                    <div className="rt-challenges-detailscontent">
                        <div className="rt-badgebox">
                            <figure className="rt-roundimage">
                                <img src={challenge.full_image} alt="" />
                            </figure>
                            <h3>{diffInDays} days</h3>
                            <span>Unearned</span>
                        </div>
                        <div className="rt-progressbarbox">
                            <p>{parseInt(challengeProgress.required_sessions) - parseInt(challengeProgress.progress)} sessions until you complete this challenge!</p>
                            <ProgressBar now={parseInt(challengeProgress.progress)*100/parseInt(challengeProgress.required_sessions)} />
                        </div>
                        <ul className="rt-memberslist">
                            {lastThree.map((user:any) => {
                                return (<li>
                                    <div className="rt-themecardbox">
                                        <figure className="rt-roundimage">
                                            <img src={ASSET_ENDPOINT+'images/'+user.avatar} alt="" />
                                        </figure>
                                        <div className="rt-themecontent">
                                            <h4>{user.full_name} </h4>
                                            <span>{user.position}</span>
                                        </div>
                                    </div>
                                </li>)
                            })}
                        </ul>
                        <span className="rt-datebox">
                            <i className="icon-calander"></i>
                            {challenge.formatted_start} - {challenge.formatted_end}
                        </span>
                        <div className="rt-description">
                            <p>{challenge.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};