import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import {sessionService} from "../../services/sessions.service";
import {authService} from "../../services/auth.service";


export const ShareGoalsModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);
    const [shareUrl, setShareUrl] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);

    let shareImage = () => {
        console.log('test')
        var node = document.getElementById('widget');
        // @ts-ignore
        node.style.visibility = 'visible';
        // @ts-ignore
        node.style.backgroundImage = authService.userInfo().avatar;
        // @ts-ignore
        htmlToImage.toPng(node)
            .then(function (ImageURL) {
                // @ts-ignore
                node.style.visibility = 'hidden';

                sessionService.shareStats({image:ImageURL}).then(({data}:any) => {
                    if (data.status_code == 200){
                        setShareUrl(data.share_url)
                    }
                })
            });
    }
    return(
        <Modal show={show} className="rt-thememodal rt-goalsmodal rt-sharegoalsmodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-goalsmodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-goalscontent">
                        <div className="rt-modaltitle">
                            <h3>SHARE SCREENSHOT</h3>
                        </div>
                        <form className="rt-formtheme">
                            <fieldset>
                                <h4>I JUST COMPLETED <span> {props.session.name}</span> <br/> ON THE BEAST MODE SOCCER+ APP! </h4>
                                <ul className="rt-goalinfolist">
                                    <li>
                                        <div className="rt-goalstatbox">
                                            <em>{props.stats.touches}</em>
                                            <span>TOUCHES</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="rt-goalstatbox">
                                            <em>{props.stats.passes}</em>
                                            <span>PASSES</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="rt-goalstatbox">
                                            <em>{props.stats.shots}</em>
                                            <span>SHOTS</span>
                                        </div>
                                    </li>
                                </ul>
                                {/*<div className="rt-description">*/}
                                {/*    <p>INSPIRE YOUR FRIENDS, AND FLEX A LITTLE!<br/> HIT THE BUTTON BELOW</p>*/}
                                {/*</div>*/}
                                {/*<div className="rt-shareinput">*/}
                                {/*    <input type="text" className="form-control" value={shareUrl} readOnly/>*/}
                                {/*    <button type="button" className="rt-sharebtn"  disabled={disableBtn} onClick={shareImage}><i className="icon-link"></i></button>*/}
                                {/*</div>*/}
                            </fieldset>
                        </form>
                    </div>
                </div>
                {/*<div className="modal-content" id="widget" style={{visibility:'hidden',background:`url(${authService.userInfo().avatar})`}}>*/}
                {/*    <div className="modal-body">*/}
                {/*        <div className="rt-thememodalbox rt-goalsmodalbox">*/}
                {/*            <div className="rt-goalscontent">*/}
                {/*                <fieldset><h4>I JUST COMPLETED <span> BMS Warm Up &amp; Juggling</span> <br></br> ON THE*/}
                {/*                    BEAST MODE SOCCER+ APP!</h4>*/}
                {/*                    <ul className="rt-goalinfolist">*/}
                {/*                        <li>*/}
                {/*                            <div className="rt-goalstatbox"><em>{props.stats.touches}</em><span>TOUCHES</span></div>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <div className="rt-goalstatbox"><em>{props.stats.passes}</em><span>PASSES</span></div>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <div className="rt-goalstatbox"><em>{props.stats.shots}</em><span>SHOTS</span></div>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                </fieldset>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Modal.Body>
        </Modal>
    )
}
