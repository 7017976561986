import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { ShareGoalsModal } from ".";
import { images } from '../../assets/image'
import {sessionService} from "../../services/sessions.service";
import {stat} from "fs";
import {useAlert} from "react-alert";


export const GoalsModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);
    const [statsData, setStatsData] = useState({
        session_id:null,
        touches:'',
        passes:'',
        shots:'',
        completed:false,
    });

    const [buttonDisabled, setButtonDisabled] = useState(false);
    console.log(props.customAlert)
    const [modalShow, setModalShow] = useState(false);

    let submitStats = async (e: any) => {
        e.preventDefault();
        setButtonDisabled(true);
        setStatsData({...statsData,session_id: props.session.id});

        statsData.session_id = props.session.id;

        sessionService.submitStats(statsData).then(async ({data:any}) => {
            await props.customAlert.success('Stats Added Successfully')
            props.onHide()
            setModalShow(true)
            setButtonDisabled(false);
        })
    }

    return(
        <Modal show={show} className="rt-thememodal rt-goalsmodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-goalsmodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-goalscontent">
                        <div className="rt-modaltitle">
                            <h3>YOU COMPLETED {props.session.name}</h3>
                        </div>
                        <form className="rt-formtheme" onSubmit={e => submitStats(e)}>
                            <fieldset>
                                <div className="rt-customcheckbox-area">
                                    <label className="rt-customcheckbox">
                                        <input type="checkbox" onChange={e => setStatsData({...statsData,completed: e.target.checked})}/>
                                        <span className="rt-checkmark"></span> 
                                    </label>
                                    <span>CLICK HERE TO CONFIRM YOU COMPLETED THIS SESSION</span>
                                </div>
                                <h4>INPUT STATS</h4>
                                <ul className="rt-goalinfolist">
                                    <li>
                                        <label>TOUCHES</label>
                                        <input type="number" className="form-control" placeholder="" value={statsData.touches} onChange={e => setStatsData({...statsData,touches: e.target.value})}/>
                                    </li>
                                    <li>
                                        <label>PASSES</label>
                                        <input type="number" className="form-control" placeholder="" value={statsData.passes} onChange={e => setStatsData({...statsData,passes: e.target.value})}/>
                                    </li>
                                    <li>
                                        <label>SHOTS</label>
                                        <input type="number" className="form-control" placeholder="" value={statsData.shots} onChange={e => setStatsData({...statsData,shots: e.target.value})}/>
                                    </li>
                                </ul>
                                <button type="submit" className="rt-btn rt-subitstat" disabled={buttonDisabled}>SUBMIT YOUR STATS</button>
                                { modalShow && < ShareGoalsModal session={props.session} stats={statsData} show={modalShow} onHide={() => {setModalShow(false); props.onHide()}} />}
                            </fieldset>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
