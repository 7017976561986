import React from 'react';
import ContentLoader from "react-content-loader";

const CategoriesLoader = (props:any) => {
    return (
        <ContentLoader
            speed={2}
            width={1561}
            height={1000}
            style={{marginTop:'-160px'}}
            viewBox="0 0 1267 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#effeb4"
            {...props}
        >
            <rect x="150" y="0" rx="16" ry="16" width="220" height="160" />
            <rect x="390" y="0" rx="16" ry="16" width="220" height="160" />
            <rect x="630" y="0" rx="16" ry="16" width="220" height="160" />
            <rect x="870" y="0" rx="16" ry="16" width="220" height="160" />

            <rect x="150" y="180" rx="16" ry="16" width="220" height="160" />
            <rect x="390" y="180" rx="16" ry="16" width="220" height="160" />
            <rect x="630" y="180" rx="16" ry="16" width="220" height="160" />
            <rect x="870" y="180" rx="16" ry="16" width="220" height="160" />

        </ContentLoader>
    );
};

export default CategoriesLoader;
