import React, {FormEvent, useState} from "react";
import { Modal } from 'react-bootstrap';
import {goalService} from "../../services/goal.service";


export const AddGoalModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const [goalData, setGoalData] = useState({
        heading : '',
        description : '',
    });
    const handleClose = () => setShow(false);

    const createGoal = (e : FormEvent) : void => {
        e.preventDefault();
      goalService.addGoal(goalData).then(({data}) => {
          if (data.status_code == 200){
              props.setProfileInfo({...props.profileInfo,goals:data.goals})
              props.onHide()
          }
      })
    }
    return(
        <Modal show={show} className="rt-thememodal rt-addgoalmodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-addgoalmodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-addgoalrcontent">
                        <div className="rt-modaltitle">
                            <h3>ADD GOAL</h3>
                        </div>
                        <form className="rt-formtheme" onSubmit={e => createGoal(e)}>
                            <fieldset>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" className="form-control" id="title-field" required placeholder="TITLE" value={goalData.heading} onChange={e => setGoalData({...goalData,heading:e.target.value})}/>
                                        <label htmlFor="title-field">TITLE</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-textarea">
                                        <textarea className="form-control" id="description-field" required onChange={e => setGoalData({...goalData,description:e.target.value})}>{goalData.description}</textarea>
                                        <label htmlFor="title-field">DESCRIPTION</label>
                                    </div>
                                </div>
                                <div className="rt-btns">
                                    <button type="button" className="rt-btn rt-border-btn" onClick={props.onHide}>CANCEL</button>
                                    <button type="submit" className="rt-btn">ADD GOAL</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
