import { images } from "../../assets/image";

export const data = [
    {
        image: images.videoimg,
        name: "1 BALL 1 WALL STACKER",
        link: "/video-details",
        description: "This is part of a session that I did with Allie Long and Alex Morgan before the 2019 World Cup, which they went on to win. It’s all about varying passing distance and great progressive touches!"
    },
    {
        image: images.videoimg,
        name: "COACH DAVID SYAS",
        link: "/video-details",
        description: "This is part of a session that I did with Allie Long and Alex Morgan before the 2019 World Cup, which they went on to win. It’s all about varying passing distance and great progressive touches!"
    },
    {
        image: images.videoimg,
        name: "SOFIA HUERTA FOOTWORK",
        link: "/video-details",
        description: "This is part of a session that I did with Allie Long and Alex Morgan before the 2019 World Cup, which they went on to win. It’s all about varying passing distance and great progressive touches!"
    },
    {
        image: images.videoimg,
        name: "SOFIA HUERTA FINISHING",
        link: "/video-details",
        description: "This is part of a session that I did with Allie Long and Alex Morgan before the 2019 World Cup, which they went on to win. It’s all about varying passing distance and great progressive touches!"
    },
    {
        image: images.videoimg,
        name: "SOFIA HUERTA TURN CUT",
        link: "/video-details",
        description: "This is part of a session that I did with Allie Long and Alex Morgan before the 2019 World Cup, which they went on to win. It’s all about varying passing distance and great progressive touches!"
    }
]