import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { GoalsModal } from '../../components/modals'
import { CourseVideoComponent } from '../../components/course-video';
import { data } from './data'
import './video-details.scss';
import {sessionService} from "../../services/sessions.service";
import VideoDetailsLoader from "./video-detail-loader";

interface IProps {
    data: any;
}

export const VideoDetails: React.FC<IProps> = (props) => {
    let history = useHistory();
    let params : any = useParams();
    const [drillId, setDrillId] = useState(params.id);
    const [loading, setLoading] = useState(true);

    const [drill, setDrill] = useState({
        name:'',
        id:'',
        title:'',
        description:'',

    });

    const [drills, setDrills] = useState([]);

    useEffect(() => {
        sessionService.drillDetail(params.id).then(({data} : any) => {
            console.log(data)
            setDrill(data.data)
            setDrills(data.drills)
            setLoading(false)
        });
    }, [drillId]);

    // @ts-ignore
    return (
        <Layout>
            {loading ? <VideoDetailsLoader /> : (
            <div className="rt-content rt-video-details">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to={''} onClick={e => {e.preventDefault(); history.goBack();}} className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>{drill.title}</h2>
                    </div>
                </div>
                <div className="rt-videocontentarea">
                    <div className="rt-banner">
                        <div className="rt-videobox">
                            <iframe src={drill.name} allowFullScreen={true} className="rt-videoplay"></iframe>
                        </div>
                        <div className="rt-bannercontent">
                            <h3>{drill.title}</h3>
                            <p>{drill.description}</p>
                        </div>
                    </div>
                    <div className="rt-coursevideos">
                        {drills.map((d: any) => (
                            <CourseVideoComponent
                                key={d.id}
                                setLoading={setLoading}
                                data={d}
                                setDrillId={setDrillId}
                                playing={d.id == drill.id}
                            />
                        ))}
                    </div>
                </div>
            </div>)}
        </Layout>
    );
};