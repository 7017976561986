export const routes = {
    home: '/',
    login: '/login',
    forgot: '/forgot',
    create: '/create',
    signupuser: '/signup-user',
    signupclub: '/signup-club',
    dashboard: '/dashboard',
    profile: '/profile',
    editprofile: '/edit-profile',
    settings: '/settings',
    traincategores: '/train-categores',
    learncategores: '/learn-categores',
    categorySessions: '/category/:id/sessions',
    categoryBmsu: '/category/:id/bmsu',
    sessionDetail: '/session/:id/detail',
    drillDetail: '/drill/:id/detail',
    coursedetails: '/course-details',
    videodetails: '/video-details',
    organizetraining: '/organize-training',
    leaderboard: '/leaderboard',
    memberprofile: '/member-profile/:id',
    progress: '/progress',
    teammates: '/teammates',
    challenges: '/challenges',
    challengesdetails: '/challenges-detail/:id',
    paypalReturn: '/subscribe/paypal/return',
    purchaseSubscription:'/purchase/subscription'
}