import React, {Component, FormEvent, useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import './signup.scss';
import {APP_URL, PAYMENT_METHOD} from "../../../environment";
import {authService} from "../../../services/auth.service";

export const PayPalReturnUser = (props:any) => {
    let history = useHistory();

    useEffect(() => {
        createAgreement();

    }, []);

    let createAgreement = async () => {
        const query = new URLSearchParams(props.location.search);

        const token = query.get('token')
        const userId = await authService.getPaypalUserId();

        authService.signupPaypalReturn({user_id:userId,token:token}).then(async ({data}) => {
            if (data.status_code == 200){
                console.log(data)
                await authService.authenticate(data.data)
                // @ts-ignore
                window.location = APP_URL+routes.dashboard
            }
        })
    }

    return (
        <div className="rt-signupholder h-100">
            <h1>Redirecting in a moment...</h1>
        </div>
    );
};