import React from 'react';
import ContentLoader from "react-content-loader";
import {authService} from "../../services/auth.service";
import {AddGoalModal, DeleteGoalModalModal, FindMemberModal, FollowingModal} from "../../components";
import {Link} from "react-router-dom";
import TrophyComponent from "../../components/profile/trophy.component";
import GoalComponent from "../../components/profile/goal.component";

const ProfileLoader = (props:any) => {
    return (
        <div className="rt-content rt-profile">
            <div className="rt-profile-inforamation">
                <figure className="rt-roundimage rt-profileimage">
                    <img src={authService.userInfo().avatar} alt="" />
                </figure>
                <div className="rt-titlebox">
                    <h2>{authService.userInfo().name}</h2>
                    <span>{authService.userInfo().position}</span>
                    <span>CHINO</span>
                </div>
                <div className="rt-profileactions">
                    <ContentLoader
                        speed={2}
                        height={80}
                        width={700}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="50" y="0" rx="8" ry="8" width="230" height="70" />
                        <rect x="300" y="0" rx="8" ry="8" width="230" height="70" />
                        <rect x="550" y="0" rx="8" ry="8" width="80" height="70" />
                    </ContentLoader>
                </div>
            </div>
            <div className="rt-counterbox">
                <div className="rt-count">
                    <ContentLoader
                        speed={2}
                        height={80}
                        width={80}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="80" height="80" />
                    </ContentLoader>
                </div>
                <div className="rt-count">
                    <ContentLoader
                        speed={2}
                        height={80}
                        width={80}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="80" height="80" />
                    </ContentLoader>
                </div>
                <div className="rt-count rt-followingmodal-btn" >
                    <ContentLoader
                        speed={2}
                        height={80}
                        width={80}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="8" ry="8" width="80" height="80" />
                    </ContentLoader>
                </div>
            </div>
            <div className="rt-trainingandtrophies">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="rt-trainingresulat-area">
                            <div className="rt-title">
                                <ContentLoader
                                    speed={2}
                                    height={40}
                                    width={250}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="8" ry="8" width="250" height="40" />
                                </ContentLoader>
                            </div>
                            <div className="rt-trainingresulat">
                                <ul className="rt-traininglist">

                                    <li>
                                        <span>
                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={250}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                            <rect x="0" y="0" rx="8" ry="8" width="150" height="40" />
                                        </ContentLoader>
                                        </span>
                                        <span>

                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={40}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                            <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                                        </ContentLoader>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={250}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                            <rect x="0" y="0" rx="8" ry="8" width="210" height="40" />
                                        </ContentLoader>
                                        </span>
                                        <span>

                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={40}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                            <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                                        </ContentLoader>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={250}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                            <rect x="0" y="0" rx="8" ry="8" width="120" height="40" />
                                        </ContentLoader>
                                        </span>
                                        <span>

                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={40}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                            <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                                        </ContentLoader>
                                        </span>
                                    </li>
                                </ul>
                                <div className="rt-resultbox">
                                    <div className="rt-resultcount">
                                        <strong>
                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={40}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                                <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                                            </ContentLoader>
                                        </strong>
                                        <span>TOUCHES</span>
                                    </div>
                                    <div className="rt-resultcount">
                                        <strong>
                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={40}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                                <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                                            </ContentLoader>
                                        </strong>
                                        <span>SHOTS</span>
                                    </div>
                                    <div className="rt-resultcount">
                                        <strong>
                                            <ContentLoader
                                                speed={2}
                                                height={40}
                                                width={40}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#effeb4"
                                                {...props}
                                            >
                                                <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                                            </ContentLoader>
                                        </strong>
                                        <span>PASSES</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="rt-trophiesarea">
                            <div className="rt-title">
                                <ContentLoader
                                    speed={2}
                                    height={40}
                                    width={250}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="8" ry="8" width="250" height="40" />
                                </ContentLoader>
                            </div>
                            <div className="rt-trophies">
                                <ul className="rt-trophilist">
                                    <li>
                                        <ContentLoader
                                            speed={2}
                                            height={50}
                                            width={50}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#effeb4"
                                            {...props}
                                        >
                                            <rect x="0" y="0" rx="50" ry="50" width="50" height="50" />
                                        </ContentLoader>
                                    </li>
                                    <li>
                                        <ContentLoader
                                            speed={2}
                                            height={50}
                                            width={50}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#effeb4"
                                            {...props}
                                        >
                                            <rect x="0" y="0" rx="50" ry="50" width="50" height="50" />
                                        </ContentLoader>
                                    </li>
                                    <li>
                                        <ContentLoader
                                            speed={2}
                                            height={50}
                                            width={50}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#effeb4"
                                            {...props}
                                        >
                                            <rect x="0" y="0" rx="50" ry="50" width="50" height="50" />
                                        </ContentLoader>
                                    </li>
                                    <li>
                                        <ContentLoader
                                            speed={2}
                                            height={50}
                                            width={50}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#effeb4"
                                            {...props}
                                        >
                                            <rect x="0" y="0" rx="50" ry="50" width="50" height="50" />
                                        </ContentLoader>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rt-goalsarea">
                <div className="rt-title">
                    <h2>GOALS</h2>
                    <button type="button" className="rt-btn rt-border-btn"><i className="icon-pluse"></i><span>add</span></button>
                </div>
                <div className="rt-goalcard">
                    <div className="rt-goalcontent">
                        <div className="rt-title">
                            <ContentLoader
                                speed={2}
                                height={65}
                                width={300}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="200" height="30" />
                                <rect x="0" y="35" rx="8" ry="8" width="300" height="30" />
                            </ContentLoader>
                        </div>
                        <ContentLoader
                            speed={2}
                            height={40}
                            width={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#effeb4"
                            {...props}
                        >
                            <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                        </ContentLoader>
                    </div>
                    <div className="rt-datebox">
                        <ContentLoader
                            speed={2}
                            height={40}
                            width={300}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#effeb4"
                            {...props}
                        >
                            <rect x="0" y="0" rx="8" ry="8" width="200" height="30" />
                        </ContentLoader>
                    </div>
                </div>
                <div className="rt-goalcard">
                    <div className="rt-goalcontent">
                        <div className="rt-title">
                            <ContentLoader
                                speed={2}
                                height={65}
                                width={300}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="200" height="30" />
                                <rect x="0" y="35" rx="8" ry="8" width="300" height="30" />
                            </ContentLoader>
                        </div>
                        <ContentLoader
                            speed={2}
                            height={40}
                            width={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#effeb4"
                            {...props}
                        >
                            <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
                        </ContentLoader>
                    </div>
                    <div className="rt-datebox">
                        <ContentLoader
                            speed={2}
                            height={40}
                            width={300}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#effeb4"
                            {...props}
                        >
                            <rect x="0" y="0" rx="8" ry="8" width="200" height="30" />
                        </ContentLoader>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ProfileLoader;
