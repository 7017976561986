import React, {Component, FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import './forgot.scss';
import {authService} from "../../../services/auth.service";


export const ForgotPage = () => {
    const [email, setEmail] = useState('');
    let history = useHistory();

    let forgetPassword = (e: FormEvent) => {
        e.preventDefault();
        authService.forgotPassword({email:email}).then(({data} :any ) => {
            localStorage.setItem('email',email)
            if (data.status_code == 200){
                history.push(routes.create)
            }
        })
    }
    return (
        <div className="rt-forgotpasswordholder">
            <div className="rt-split rt-leftsite">
                <figure className="rt-signupimg"><img src={images.signupimg} alt="" /></figure>
            </div>
            <div className="rt-split rt-rightsite">
                <div className="rt-flexcontainer">
                    <div className="rt-formbox">
                        <form className="rt-formtheme rt-forgotpasswordform" onSubmit={forgetPassword}>
                            <fieldset>
                                <div className="rt-logobox">
                                    <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                                </div>
                                <div className="form-group">
                                    <h3>FORGOT PASSWORD</h3>
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="email" name="email" className="form-control" required id="email-field" placeholder="EMAIL ADDRESS" value={email} onChange={e => setEmail(e.target.value)} />
                                        <label htmlFor="email-field">EMAIL ADDRESS</label>
                                    </div>
                                </div>
                                <div className="form-group m-0">
                                    <button className="rt-btn rt-btn-lg p-0" type="submit">SEND INSTRUCTIONS</button>
                                </div>
                                <div className="form-group rt-signuplogintextbox text-center">
                                    <span>REMEMBER PASSWORD? <Link to={routes.login}>LOGIN</Link></span>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};