import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { MemberSessionCardComponent } from '../../components';
import { data } from './data'
import './member-profile.scss';
import {userProfileService} from "../../services/profile.service";
import {authService} from "../../services/auth.service";
import {ASSET_ENDPOINT} from "../../environment";
import MemberProfileLoader from "./member-profile-loader";

export const MemberProfilePage = () => {
    let param : any = useParams();
    const [loading, setLoading] = useState(true);
    const [profileInfo, setProfileInfo] = useState({
        touches: 0,
        passes: 0,
        shots: 0,
        total_sessions: 0,
        completed_stats: [],
        achievements: [],
        goals: [],
        user : {
            followers:0,
            following:0,
            is_followed_count:0,
            avatar:'',
            id:'',
            name:'',
            is_verified:0,
        }
    });
    useEffect(() => {
        userProfileService.memberProfile(param.id).then(({data}) => {
            if (data.status_code == 200){
                setProfileInfo(data.data)
                setLoading(false)
            }
        });
    }, []);

    let unfollowUser = () => {
        userProfileService.unfollow(profileInfo.user.id).then(({data}:any) => {
            if (data.status_code == 200){
                setProfileInfo({...profileInfo,user:{...profileInfo.user,is_followed_count: !(profileInfo.user.is_followed_count) == true ? 1 : 0}})
            }
        })
    }

    let followUser = () => {
        userProfileService.follow(profileInfo.user.id).then(({data}:any) => {
            if (data.status_code == 200){
                setProfileInfo({...profileInfo,user:{...profileInfo.user,is_followed_count: !(profileInfo.user.is_followed_count) == true ? 1 : 0}})
            }
        })
    }


    return (
        <Layout title="Leaderboard">
            {loading? <MemberProfileLoader /> : (<div className="rt-content rt-memberprofile">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to="/leaderboard" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>PROFILE</h2>
                    </div>
                </div>
                <div className="rt-profile-inforamation">
                    <div className='rt-imgwithbadge'>
                        <figure className="rt-roundimage rt-profileimage rt-badge">
                            <img src={ASSET_ENDPOINT+'images/'+profileInfo.user.avatar} alt="" />
                            {profileInfo.user.is_verified == 1 ? (<span className="rt-roundicon">
                            <i className="icon-tick"></i>
                        </span>) : ''}
                        </figure>
                    </div>
                    <div className="rt-titlebox">
                        <h2>{profileInfo.user.name}</h2>
                    </div>
                    {profileInfo.user.id != authService.userId() ?
                    <div className="rt-profileactions">
                        {profileInfo.user.is_followed_count == 1 ?
                            <button className="rt-btn rt-border-btn" onClick={unfollowUser}><span>UNFOLLOW</span></button>:
                            <button className="rt-btn rt-black-btn" onClick={followUser}><span>FOLLOW</span></button>
                        }
                    </div>
                        : ''}
                </div>
                <div className="rt-counterbox">
                    <div className="rt-count">
                        <h3>{profileInfo.total_sessions}</h3>
                        <span>SESSIONS</span>
                    </div>
                    <div className="rt-count">
                        <h3>{profileInfo.user.followers ?? 0}</h3>
                        <span>FOLLOWERS</span>
                    </div>
                    <div className="rt-count rt-followingmodal-btn">
                        <h3>{profileInfo.user.following}</h3>
                        <span>FOLLOWING</span>
                    </div>
                </div>
                <div className="rt-trainingsessions">
                    <div className="rt-title">
                        <h2>TRAINING SESSIONS</h2>
                    </div>
                    <div className="rt-courses">
                        {profileInfo.completed_stats.map((d: any) => (
                            <MemberSessionCardComponent
                                data={d}
                            />
                        ))}
                    </div>
                </div>
            </div>)}
        </Layout>
    );
};