import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { routes } from './routesConstants';
import {authService} from "../services/auth.service";

const PrivateRoutes = ({ component: Page, authToken, ...rest }: any) => {
    console.log('inside');
    return (
        <Route {...rest} render={(props:any) => {
            return (
                <>
                    {authService.isAuthenticated() && authService.authToken() != undefined ? <Page {...props} /> : <Redirect to={routes.login} />}
                </>
            )
        }} />
    );
}

export default PrivateRoutes