import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class GoalService extends HttpService {
    private readonly prefix: string = '';
    constructor() {
        super();
        HttpService.setToken(authService.authToken())
    }
    /**
     * Dashboard Data Service
     */
    deleteGoal = (data:any) => this.post(`${this.prefix}${APIEndpoints.DELETE_GOAL}`,data);

    addGoal = (data:any) => this.post(`${this.prefix}${APIEndpoints.ADD_GOAL}`,data);


}

export const goalService = new GoalService();
