import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import {goalService} from "../../services/goal.service";


export const DeleteGoalModalModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);

    let deleteGoal = () => {
        goalService.deleteGoal({noteId:props.goal.id}).then(({data}) => {
           if (data.status_code == 200){
               props.setProfileInfo({...props.profileInfo,goals:data.goals})
               props.onHide()
           }
        })
    }

    return(
        <Modal show={show} className="rt-thememodal rt-deletemodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-deletemodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-deletecontent">
                        <div className="rt-modaltitle">
                            <h3>WANT TO DELETE GOAL?</h3>
                            <div className="rt-description">
                                <p>Are you sure want to delete work on left foot.</p>
                            </div>
                        </div>
                        <div className="rt-btns">
                            <button type="button" className="rt-btn rt-border-btn" onClick={props.onHide}>CANCEL</button>
                            <button type="button" className="rt-btn" onClick={deleteGoal}>DELETE</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
