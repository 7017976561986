import React from 'react';
import ContentLoader from "react-content-loader";

const LeaderboardTop3Loader = (props:any) => {
    return (
        <ContentLoader
            speed={2}
            width={1561}
            height={130}
            viewBox="0 0 1561 160"
            style={{padding:'0px',margin:'0px'}}
            backgroundColor="#f3f3f3"
            foregroundColor="#effeb4"
            {...props}
        >
            <circle cx="200" cy="70" r="60" />
            <rect x="270" y="30" rx="14" ry="14" width="80" height="20" />
            <rect x="270" y="60" rx="14" ry="14" width="150" height="20" />
            <rect x="270" y="90" rx="14" ry="14" width="130" height="20" />

            <circle cx="700" cy="70" r="60" />
            <rect x="770" y="30" rx="14" ry="14" width="80" height="20" />
            <rect x="770" y="60" rx="14" ry="14" width="150" height="20" />
            <rect x="770" y="90" rx="14" ry="14" width="130" height="20" />

            <circle cx="1200" cy="70" r="60" />
            <rect x="1270" y="30" rx="14" ry="14" width="80" height="20" />
            <rect x="1270" y="60" rx="14" ry="14" width="150" height="20" />
            <rect x="1270" y="90" rx="14" ry="14" width="130" height="20" />

        </ContentLoader>
    );
};

export default LeaderboardTop3Loader;
