import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { images } from '../../assets/image'


export const SignupModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return(
        <Modal show={show} className="rt-thememodal rt-registrationmodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-registrationmodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-registercontent">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="rt-formtheme rt-formregistration">
                                        <fieldset>
                                            <h4>SIGN UP <span>Please sign up as:</span></h4>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Link to="/signup-user">
                                                            <img src={images.userimg} alt="" />
                                                            <span>USER</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Link to="/signup-club">
                                                            <img src={images.clubimg} alt="" />
                                                            <span>CLUB</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )

}
