import React, {Component, FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import '../signup/signup.scss';
import '../signup/react-phone-number.css';
import {APP_URL, PAYMENT_METHOD} from "../../../environment";
import {authService} from "../../../services/auth.service";
import Phone from 'react-phone-number-input'
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
// @ts-ignore
import { useAlert } from "react-alert";
import {HttpService} from "../../../services/base.service";



interface UserData {
    payment_method:string,
    cardData:any,
    agreement:any,
}

export const PurchaseSubscription = () => {
    const [membershipDuration, setMembershipDuration] = useState('monthly');
    const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.chargebee);
    const [cardData, setCardData] = useState({
        cardNumber:'',
        expiry:'',
        cvc:'',
        error:false
    });
    const [errors, setErrors] = useState({
        agreement:false
    });
    const [userData, setUserData] = useState<UserData>({
        payment_method: paymentMethod,
        cardData : cardData,
        agreement:false
    });

    const [monthlyPlan, setMonthlyPlan] = useState({
        name:undefined,
        price:''
    });

    const [yearlyPlan, setYearlyPlan] = useState({
        name:undefined,
        price:''
    });
    const alert = useAlert();

    let signUp = (e : FormEvent) : void => {
        e.preventDefault();
        console.log('test')
        if (!userData.agreement){
            alert.error('Please accept agreement')
            setErrors({...errors,agreement: true})
            // @ts-ignore
            return 0;
        }

        setUserData({...userData,payment_method:paymentMethod})
        setUserData({...userData,cardData:cardData})
        userData.payment_method = paymentMethod;
        userData.cardData = cardData;
        console.log(sessionStorage.getItem('token'))

        HttpService.setToken(sessionStorage.getItem('token') ?? "");
        authService.purchaseSubscription(userData).then(async ({data}) => {
            console.log(data)
            if (data.status_code == 200){
                if (paymentMethod == PAYMENT_METHOD.paypal){
                    await authService.setPaypalUserId(data.user_id);
                    window.location = data.redirect_url
                }else{
                    authService.authenticate(data.data)
                    // @ts-ignore
                    window.location = APP_URL+routes.dashboard
                }

            }else{
                if (data.status == 'error'){
                    alert.error(data.error)
                }

                if (data.status_code == 302){
                    alert.error(data.errors[0])

                }
            }
        })
    }

    let changeAgreement = (e : any) => {
        setUserData({...userData,agreement:e.target.checked})
    }

    useEffect(() => {
        alert.error('Your account is deactivated, please purchase subscription to continue')
        authService.getPlans().then(({data}: any) => {
            if (data.status_code == 200){
                console.log(data)
                setMonthlyPlan(data.monthlyPlan)
                setYearlyPlan(data.yearlyPlan)
            }
        })
    }, []);


    return (
        <div className="rt-signupholder h-100">
            <div className="rt-heading">
                <h2>MEMBERSHIP</h2>
            </div>
            <div className="rt-membershipplan">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav className="rt-navtabs">
                        <Nav.Item>
                            <Nav.Link eventKey="first" onClick={() => setMembershipDuration('monthly')}>MONTHLY</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second" onClick={() => setMembershipDuration('yearly')}>YEARLY(SAVE 17.9%)</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className="rt-tabcontentarea">
                                <div className="rt-themecardbox">
                                    <div className="rt-roundicon">
                                        <i className="icon-dollar"></i>
                                    </div>
                                    <div className="rt-themecontent">
                                        <span><strong>${parseInt(monthlyPlan.price) > 0 ? parseInt(monthlyPlan.price)/100 : '0.00'} </strong> Monthly Validity</span>
                                        <h4>{monthlyPlan.name ?? 'Loading...'}</h4>
                                        <em>Unlimited Programs, Sessions & Drills</em>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className="rt-tabcontentarea">
                                <div className="rt-themecardbox">
                                    <div className="rt-roundicon">
                                        <i className="icon-dollar"></i>
                                    </div>
                                    <div className="rt-themecontent">
                                        <span><strong>$197 </strong> Yearly Validity</span>
                                        <h4>MST Yearly</h4>
                                        <em>Unlimited Programs, Sessions & Drills</em>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <div className="rt-formbox">
                <form className="rt-formtheme rt-forgotpasswordform" onSubmit={signUp}>
                    <fieldset>
                        {/* <div className="rt-logobox">
                            <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                        </div> */}
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center mt-30">
                                    <h3>SELECT PAYMENT METHOD</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-customradiobox">
                                        <div className="rt-customradio-area">
                                            <label className="rt-customradio">
                                                <input type="radio" name="paymentMethod" checked={paymentMethod == PAYMENT_METHOD.chargebee} value={PAYMENT_METHOD.chargebee} onChange={e => setPaymentMethod( e.target.value)}/>
                                                <span className="rt-checkmark"></span>
                                            </label>
                                            <span> CREDIT CARD</span>
                                        </div>
                                        <figure className="rt-cardsimage m-0">
                                            <img src={images.cards} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <CreditCardInput
                                    onError={(err : any) => {
                                        setCardData({...cardData,error: true});
                                        console.log(err)
                                    }}
                                    cardNumberInputProps={{ value: cardData.cardNumber, onChange: (e: any) => setCardData({...cardData,cardNumber: e.target.value}) }}
                                    cardExpiryInputProps={{ value: cardData.expiry, onChange: (e: any) => setCardData({...cardData,expiry: e.target.value}) }}
                                    cardCVCInputProps={{ value: cardData.cvc, onChange: (e: any) => setCardData({...cardData,cvc: e.target.value}) }}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-customradiobox">
                                        <div className="rt-customradio-area">
                                            <label className="rt-customradio">
                                                <input type="radio" name="paymentMethod" value={PAYMENT_METHOD.paypal} onChange={e => setPaymentMethod(e.target.value)}/>
                                                <span className="rt-checkmark"></span>
                                            </label>
                                            <span>SIGN UP VIA PAYPAL</span>
                                        </div>
                                        <figure className="rt-cardsimage m-0">
                                            <img src={images.paymenticon} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-customcheckbox-area">
                                        <label className="rt-customcheckbox">
                                            <input type="checkbox" value={userData.agreement} name="agreement" onChange={e => changeAgreement(e)}/>
                                            <span className="rt-checkmark"></span>
                                        </label>
                                        <span>I have read and agree to the <a href="javascript:void(0);">Terms</a> of Use and <a href="javascript:void(0);">Privacy Policy</a></span>
                                    </div>
                                    <p className="sc-eCImPb hUcdXv" style={errors.agreement ? {display:'block'} : {display:'none'}}>Please accept the agreement</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group m-0">
                                    <button className="rt-btn rt-btn-lg p-0" type="submit">Purcahse</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};