import React, { useEffect, useState } from "react";
import { images } from "../../assets/image";
import { Link } from "react-router-dom";
import './course-card.scss'

interface IProps {
    data: any;
    isBmsu?:any
}

export const CourseCardComponent: React.FC<IProps> = (props) => {

    return (

        <div className="rt-coursecard">
            <figure className="rt-courseimg">
                <Link to={props.isBmsu ? '/drill/'+props.data.id+'/detail' : '/session/'+props.data.id+'/detail'}>
                    <img src={props.data.full_image} alt="" />
                    <figcaption className="rt-course-title">
                        <h3>{props.data.name}<span>{props.data.second_subtitle}</span></h3>
                    </figcaption>
                </Link>
            </figure>
            <div className="rt-course-content">
                <p>{props.data.description}</p>
            </div>
        </div>

    )
}