import React from 'react';
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {WonChallengeModalModal} from "../../components";

const ChallangesLoader = (props:any) => {
    return (
        <div className="rt-content rt-challenges">
            <div className="rt-challengesbox">
                <div className="rt-title">
                    <h2>ALL CHALLENGES</h2>
                </div>
                <ul className="rt-challengeslist">

                    <ContentLoader
                        speed={2}
                        width={500}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="50" ry="50" width="100" height="100" />
                        <rect x="0" y="110" rx="16" ry="16" width="100" height="20" />

                        <rect x="135" y="0" rx="50" ry="50" width="100" height="100" />
                        <rect x="135" y="110" rx="16" ry="16" width="100" height="20" />

                        <rect x="270" y="0" rx="50" ry="50" width="100" height="100" />
                        <rect x="270" y="110" rx="16" ry="16" width="100" height="20" />

                    </ContentLoader>
                </ul>
            </div>
            <div className="rt-challengesbox">
                <div className="rt-title">
                    <h2>WON CHALLENGES</h2>
                </div>
                <ul className="rt-challengeslist">
                    <ContentLoader
                        speed={2}
                        width={500}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="50" ry="50" width="100" height="100" />
                        <rect x="0" y="110" rx="16" ry="16" width="100" height="20" />

                        <rect x="135" y="0" rx="50" ry="50" width="100" height="100" />
                        <rect x="135" y="110" rx="16" ry="16" width="100" height="20" />

                        <rect x="270" y="0" rx="50" ry="50" width="100" height="100" />
                        <rect x="270" y="110" rx="16" ry="16" width="100" height="20" />

                    </ContentLoader>
                </ul>
            </div>
        </div>

    );
};

export default ChallangesLoader;
