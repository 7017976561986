import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../assets/image';
import { routes } from '../../routes/routesConstants';
import { render } from 'react-dom'
import { Layout } from '../../components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CategoryCardComponent } from '../../components'
import { data } from './data'
import './dashboard.scss';
import {dashboardService} from "../../services/dashboard.service";
import DashboardSessionComponent from "../../components/dashboard/session.component";
import interactionPlugin from "@fullcalendar/interaction";
import DashboardLoader from "./dashboard-loader";

interface DashboardStats {
    completed_session_count : number,
    achievements : number,
    today_sessions : any,
    upcoming_sessions : any,
    this_month_sessions : any,
}
export const DashboardPage = () => {

    const [dashboardStats, setDashboardStats] = useState({
        completed_session_count : 0,
        achievements : 0,
        today_sessions: [],
        upcoming_sessions: [],
        this_month_sessions : []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dashboardService.dashboard().then(({data})=>{
            if (data.status_code == 200){
                setDashboardStats(data.data)
                setLoading(false)
            }
        })

        const yourDate = new Date()
        let date = yourDate.toISOString().split('T')[0]

        document.querySelectorAll("td[data-date='" + date + "']").forEach((e:any) => {
            e.classList.add('day-highlight')
        })
    }, []);

    return (
        <Layout title="Dashboard">
            {loading ? <DashboardLoader /> : <div className="rt-content rt-dashboard">
                <div className="rt-statandcategoryarea">
                    <div className="rt-statistics">
                        <div className="rt-statistic">
                            <div className="rt-content">
                                <em>{dashboardStats.completed_session_count}</em>
                                <h3>SESSIONS <span>COMPLETED</span></h3>
                            </div>
                            <div className="rt-iconbox">
                                <i className="icon-football"></i>
                            </div>
                        </div>
                        <div className="rt-statistic">
                            <div className="rt-content">
                                <em>{dashboardStats.achievements}</em>
                                <h3>TOTAL <span>ACHIEVEMENTS</span></h3>
                            </div>
                            <div className="rt-iconbox">
                                <i className="icon-tropy"></i>
                            </div>
                        </div>
                    </div>
                    <div className="rt-categores">
                        {data.map((d: any) => (
                            <CategoryCardComponent
                                data={d}
                                link={true}
                            />
                        ))}
                    </div>
                    <div className="rt-schedule">
                        <div className="rt-title">
                            <h2>Schedule</h2>
                        </div>
                        <FullCalendar
                            plugins={[ dayGridPlugin,interactionPlugin ]}
                            initialView="dayGridMonth"
                            events={dashboardStats.this_month_sessions}
                        />
                    </div>
                </div>
                <div className="rt-sessionslistarea">
                    <div className="rt-sessionslistholder">
                        <div className="rt-title">
                            <h2>TODAY’S SESSIONS</h2>
                        </div>
                        <div className="rt-sessionslist">
                            {dashboardStats.today_sessions.map((session : any) =>  {
                                return <DashboardSessionComponent session={session} />
                            })}
                        </div>
                        <div className="rt-title">
                            <h2>UPCOMING SESSIONS</h2>
                        </div>
                        <div className="rt-sessionslist">
                            {dashboardStats.upcoming_sessions.map((session : any) =>  {
                                return <DashboardSessionComponent session={session} />
                            })}
                        </div>
                    </div>
                </div>
            </div>}
        </Layout>
    );
};
