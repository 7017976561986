import React from 'react';
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {CourseVideoComponent, GoalsModal} from "../../components";
import {ASSET_ENDPOINT, ASSET_ENDPOINT_LIVE} from "../../environment";
import ReactPaginate from "react-paginate";

const TeamMatesLoader = (props:any) => {
    return (
        <div className="rt-content rt-teammates">

                <div className="rt-teamcard">
                    <Link to={''}>
                        <div className="rt-themecardbox rt-teamhead">
                                <ContentLoader
                                    speed={2}
                                    height={80}
                                    style={{marginTop:'-0px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <circle cx="40" cy="40" r="40" />

                                </ContentLoader>
                            <div className="rt-themecontent">
                                <ContentLoader
                                    speed={2}
                                    height={80}

                                    style={{marginLeft:'-215px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="16" ry="16" width="150" height="22" />
                                    <rect x="0" y="30" rx="16" ry="16" width="80" height="20" />
                                    <rect x="0" y="55" rx="16" ry="16" width="120" height="18" />

                                </ContentLoader>
                            </div>
                        </div>
                        <div className="rt-teamcontent">
                            <figure className="rt-bannerimg">
                                <ContentLoader
                                    speed={2}
                                    height={400}
                                    width={1500}
                                    style={{marginTop:'-0px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="16" ry="16" width="1040" height="280" />

                                </ContentLoader>

                            </figure>
                            <div className="rt-counterbox">
                                <ContentLoader
                                    speed={2}
                                    height={100}
                                    width={1500}
                                    style={{marginTop:'-0px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="150" y="0" rx="16" ry="16" width="100" height="80" />
                                    <rect x="450" y="0" rx="16" ry="16" width="100" height="80" />
                                    <rect x="750" y="0" rx="16" ry="16" width="100" height="80" />

                                </ContentLoader>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="rt-teamcard">
                    <Link to={''}>
                        <div className="rt-themecardbox rt-teamhead">
                                <ContentLoader
                                    speed={2}
                                    height={80}
                                    style={{marginTop:'-0px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <circle cx="40" cy="40" r="40" />

                                </ContentLoader>
                            <div className="rt-themecontent">
                                <ContentLoader
                                    speed={2}
                                    height={80}

                                    style={{marginLeft:'-215px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="16" ry="16" width="150" height="22" />
                                    <rect x="0" y="30" rx="16" ry="16" width="80" height="20" />
                                    <rect x="0" y="55" rx="16" ry="16" width="120" height="18" />

                                </ContentLoader>
                            </div>
                        </div>
                        <div className="rt-teamcontent">
                            <figure className="rt-bannerimg">
                                <ContentLoader
                                    speed={2}
                                    height={400}
                                    width={1500}
                                    style={{marginTop:'-0px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="16" ry="16" width="1040" height="280" />

                                </ContentLoader>

                            </figure>
                            <div className="rt-counterbox">
                                <ContentLoader
                                    speed={2}
                                    height={100}
                                    width={1500}
                                    style={{marginTop:'-0px'}}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="150" y="0" rx="16" ry="16" width="100" height="80" />
                                    <rect x="450" y="0" rx="16" ry="16" width="100" height="80" />
                                    <rect x="750" y="0" rx="16" ry="16" width="100" height="80" />

                                </ContentLoader>
                            </div>
                        </div>
                    </Link>
                </div>
        </div>
    );
};

export default TeamMatesLoader;
