import React, {useEffect, useState} from 'react';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { CategoryCardComponent } from '../../components/category-card';
import { data } from './data'
import './train-categores.scss';
import {sessionService} from "../../services/sessions.service";
import CategoriesLoader from "./categories-loader";

export const TrainCategoresPage = (props:any) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        sessionService.categories().then(({data} : any) => {
            setCategories(data.data.categories)
            setLoading(false)
        })
    }, []);
    return (
        <Layout title="Just Train">
            {loading ? <CategoriesLoader /> : (
            <div className="rt-content rt-train-categores">
                <div className="rt-categores">
                    {categories.map((d: any) => (
                        <CategoryCardComponent 
                            data={d}
                        />
                    ))}
                </div>
            </div>)}
        </Layout>
    );
};