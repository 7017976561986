import React from 'react';
import ContentLoader from "react-content-loader";

const LeaderboardLoader = (props:any) => {
    return (<><tr>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px',width:'100%',height:'100%'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <circle cx="80" cy="70" r="30" />


                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="150" height="30" />

                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="130" height="30" />

                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="120" height="30" />

                </ContentLoader>
            </td>
        </tr><tr>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px',width:'100%'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <circle cx="80" cy="70" r="30" />


                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="150" height="30" />

                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="130" height="30" />

                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="120" height="30" />

                </ContentLoader>
            </td>
        </tr><tr>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px',width:'100%'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <circle cx="80" cy="70" r="30" />


                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="150" height="30" />

                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="130" height="30" />

                </ContentLoader>
            </td>
            <td>
                <ContentLoader
                    speed={2}
                    height={120}
                    style={{padding:'0px',margin:'0px'}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="80" y="50" rx="20" ry="20" width="120" height="30" />

                </ContentLoader>
            </td>
        </tr></>
    );
};

export default LeaderboardLoader;
