export const images = {
    logo: require('./images/logo.svg').default,
    signuplogo: require('./images/auth-logo.png').default,
    signupimg: require('./images/signup-img.jpg').default,
    signupbg: require('./images/signup-bg.jpg').default,
    userimg: require('./images/user-img.png').default,
    clubimg: require('./images/club-img.png').default,
    cards: require('./images/cards.png').default,
    bannerimg: require('./images/banner-img.jpg').default,
    bannerimg2: require('./images/banner-img2.jpg').default,
    paymenticon: require('./images/payment-icon.png').default,
    userprofileimage: require('./images/user-profileimg.png').default,
    profileimage: require('./images/profile-img.png').default,
    profileimage2: require('./images/profile-img2.jpg').default,
    trophi01: require('./images/trophies/01.png').default,
    trophi02: require('./images/trophies/02.png').default,
    trophi03: require('./images/trophies/03.png').default,
    trophi04: require('./images/trophies/04.png').default,
    memberimg01: require('./images/member-images/01.png').default,
    memberimg02: require('./images/member-images/02.png').default,
    memberimg03: require('./images/member-images/03.png').default,
    memberimg04: require('./images/member-images/04.png').default,
    memberimg05: require('./images/member-images/05.png').default,
    memberimg06: require('./images/member-images/06.png').default,
    categoryimg01: require('./images/organize_my_training.png').default,
    categoryimg02: require('./images/just_train.png').default,
    categoryimg03: require('./images/learn_new.png').default,
    categoryimg04: require('./images/category-images/04.jpg').default,
    categoryimg05: require('./images/category-images/05.jpg').default,
    categoryimg06: require('./images/category-images/06.jpg').default,
    categoryimg07: require('./images/category-images/07.jpg').default,
    categoryimg08: require('./images/category-images/08.jpg').default,
    categoryimg09: require('./images/category-images/09.jpg').default,
    categoryimg10: require('./images/category-images/10.jpg').default,
    categoryimg11: require('./images/category-images/11.jpg').default,
    categoryimg12: require('./images/category-images/12.jpg').default,
    categoryimg13: require('./images/category-images/13.jpg').default,
    categoryimg14: require('./images/category-images/14.jpg').default,
    categoryimg15: require('./images/category-images/15.jpg').default,
    categoryimg16: require('./images/category-images/16.jpg').default,
    categoryimg17: require('./images/category-images/17.jpg').default,
    categoryimg18: require('./images/category-images/18.jpg').default,
    categoryimg19: require('./images/category-images/19.jpg').default,
    categoryimg20: require('./images/category-images/20.jpg').default,
    categoryimg21: require('./images/category-images/21.jpg').default,
    teamimg01: require('./images/team-images/01.png').default,
    teamimg02: require('./images/team-images/02.png').default,
    teamimg03: require('./images/team-images/03.png').default,
    teamimg04: require('./images/team-images/04.png').default,
    teamimg05: require('./images/team-images/05.png').default,
    teamimg06: require('./images/team-images/06.png').default,
    teamimg07: require('./images/team-images/07.png').default,
    teamimg08: require('./images/team-images/08.png').default,
    teamimg09: require('./images/team-images/09.png').default,
    teamimg10: require('./images/team-images/10.jpg').default,
    teamimg11: require('./images/team-images/11.jpg').default,
    teamimg12: require('./images/team-images/12.jpg').default,
    teamimg13: require('./images/team-images/13.jpg').default,
    teamimg14: require('./images/team-images/14.jpg').default,
    teamimg15: require('./images/team-images/15.jpg').default,
    videoimg: require('./images/video-images/01.jpg').default,


}