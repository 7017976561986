import React, {useEffect, useState} from "react";
import { Modal } from 'react-bootstrap';
import { images } from '../../assets/image'
import {userProfileService} from "../../services/profile.service";
import {ASSET_ENDPOINT} from "../../environment";


export const FollowingModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const [nextPage, setNextPage] = useState(1);
    const [members, setMembers] = useState([]);
    const [searchVal, setSearch] = useState('');
    const handleClose = () => setShow(false);

    useEffect(() => {
        userProfileService.follows(nextPage,'', props.showFollower).then(({data} : any) => {
            if (data.status_code == 200){
                setMembers(data.follows.data)
                setNextPage(parseInt(data.follows.current_page) + 1 )
            }
        })
    }, []);


    let handleScroll = (e : any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            userProfileService.follows(nextPage,searchVal,props.showFollower).then(({data} : any) => {
                if (data.status_code == 200){
                    if (data.follows.data.length  > 0) {
                        data.follows.data.map((val: any) => {
                            if (val.follows != null || val.followers != null) {
                                // @ts-ignore
                                members.push(val);
                            }
                        })
                        setMembers(members)
                        setNextPage(parseInt(data.follows.current_page) + 1)
                    }
                }
            })
        }
    }

    let search = (e : any) => {
        setSearch(e.target.value)
        userProfileService.follows(1,e.target.value,props.showFollower).then(({data} : any) => {
            if (data.status_code == 200){
                if (data.follows.data.length  > 0) {
                    setMembers(data.follows.data)
                    setNextPage(parseInt(data.follows.current_page) + 1)
                }
            }
        })

    }

    let unfollowUser = (id : number) => {
        userProfileService.unfollow(id).then(({data}:any) => {
            if (data.status_code == 200){
                let newMembers : any= [];
                members.map((member:any) => {
                    if (member.follows != null) {
                        if (member.follows.id == id) {
                            member.follows.is_followed_count = 0
                            props.setFollowingCount(parseInt(props.followingCount) - 1)
                        }
                    }else{
                        if (member.followers.id == id) {
                            member.followers.is_followed_count = 0
                            props.setFollowingCount(parseInt(props.followingCount) - 1)
                        }
                    }
                    newMembers.push(member)
                })
                setMembers(newMembers);
            }
        })
    }

    let followUser = (id:number) => {
        userProfileService.follow(id).then(({data}:any) => {
            if (data.status_code == 200){
                let newMembers :any = [];
                members.map((member:any) => {
                    if (member.follows != null) {
                        if (member.follows.id == id) {
                            member.follows.is_followed_count = 1
                            props.setFollowingCount(parseInt(props.followingCount) + 1)
                        }
                    }else{
                        if (member.followers.id == id) {
                            member.followers.is_followed_count = 1
                            props.setFollowingCount(parseInt(props.followingCount) + 1)
                        }
                    }
                    newMembers.push(member)
                })
                setMembers(newMembers);
            }
        })
    }

    return(
        <Modal show={show} className="rt-thememodal rt-findmembermodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-findmembermodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-findmembercontent">
                        <div className="rt-modaltitle">
                            <h3>Following</h3>
                        </div>
                        <form className="rt-formtheme">
                            <fieldset>
                                <ul className="rt-memberlist">
                                    {members.length > 0 ?  members.map((member:any) => {
                                        if (member.follows != null) {
                                            return (<li>
                                                <div className="rt-themecardbox">
                                                    <figure className="rt-roundimage rt-badge">
                                                        <img src={ASSET_ENDPOINT + 'images/' + member.follows.avatar} alt=""/>
                                                        {member.follows.is_verified == 1 ? (<span className="rt-roundicon">
                                                            <i className="icon-tick"></i>
                                                        </span>) : ''}
                                                    </figure>
                                                    <div className="rt-themecontent">
                                                        <h4>{member.follows.full_name}</h4>
                                                        <span>{member.follows.position}</span>
                                                    </div>
                                                </div>
                                                {member.follows.is_followed_count > 0 ?
                                                    <button type="button" className="rt-btn rt-black-btn"
                                                            onClick={() => unfollowUser(member.follows.id)}>UNFOLLOW</button> :
                                                    <button type="button" className="rt-btn rt-black-btn"
                                                            onClick={() => followUser(member.follows.id)}>FOLLOW</button>
                                                }
                                            </li>)
                                        }
                                        if (member.followers != null) {
                                            return (<li>
                                                <div className="rt-themecardbox">
                                                    <figure className="rt-roundimage rt-badge">
                                                        <img src={ASSET_ENDPOINT + 'images/' + member.followers.avatar} alt=""/>
                                                        {member.followers.is_verified == 1 ? (<span className="rt-roundicon">
                                                            <i className="icon-tick"></i>
                                                        </span>) : ''}
                                                    </figure>
                                                    <div className="rt-themecontent">
                                                        <h4>{member.followers.full_name}</h4>
                                                        <span>{member.followers.position}</span>
                                                    </div>
                                                </div>
                                                {member.followers.is_followed_count > 0 ?
                                                    <button type="button" className="rt-btn rt-black-btn"
                                                            onClick={() => unfollowUser(member.followers.id)}>UNFOLLOW</button> :
                                                    <button type="button" className="rt-btn rt-black-btn"
                                                            onClick={() => followUser(member.followers.id)}>FOLLOW</button>
                                                }
                                            </li>)
                                        }
                                    }) : 'No Data Available'}
                                </ul>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
