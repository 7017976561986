import React from 'react';
import {images} from "../../assets/image";
import {ASSET_ENDPOINT} from "../../environment";

const TrophyComponent = ({trophy} : any) => {
    return (
        <li>
            <figure className="rt-roundimage">
                <img src={ASSET_ENDPOINT+trophy.image} alt="" />
            </figure>
        </li>
    );
};

export default TrophyComponent;
