import React, {useEffect, useState} from 'react';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { CategoryCardComponent } from '../../components/category-card';
import { data } from './data'
import './learn-categores.scss';
import {sessionService} from "../../services/sessions.service";
import CategoriesLoader from "../train-categores/categories-loader";


export const LearnCategoresPage = () => {

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sessionService.bmsuCategories().then(({data} : any) => {
            setCategories(data.data.categories)
            setLoading(false)
        })
    }, []);

    return (
        <Layout title="Learn">
            {loading ? <CategoriesLoader /> : (
            <div className="rt-content rt-train-categores">
                <div className="rt-categores">
                    {categories.map((d: any) => (
                        <CategoryCardComponent
                            data={{...d,link:'/category/'+d.id+'/bmsu'}}
                            link={true}
                        />
                    ))}
                </div>
            </div>
            )}
        </Layout>
    );
};