import React from 'react';
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {CourseVideoComponent, GoalsModal, MemberSessionCardComponent} from "../../components";
import {ASSET_ENDPOINT} from "../../environment";

const MemberProfileLoader = (props:any) => {
    return (
        <div className="rt-content rt-memberprofile">
            <div className="rt-pageheading">
                <div className="rt-title">
                    <Link to="/leaderboard" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                    <h2>PROFILE</h2>
                </div>
            </div>
            <div className="rt-profile-inforamation">
                <ContentLoader
                    speed={2}
                    width={600}
                    height={320}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#effeb4"
                    {...props}
                >
                    <rect x="200" y="0" rx="150" ry="150" width="150" height="150" />
                    <rect x="200" y="170" rx="16" ry="16" width="150" height="30" />

                    <rect x="80" y="240" rx="16" ry="16" width="200" height="80" />
                    <rect x="310" y="240" rx="16" ry="16" width="200" height="80" />


                </ContentLoader>
            </div>
            <div className="rt-counterbox">
                <div className="rt-count">
                    <ContentLoader
                        speed={2}
                        width={50}
                        height={60}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />


                    </ContentLoader>
                    <span>SESSIONS</span>
                </div>
                <div className="rt-count">
                    <ContentLoader
                        speed={2}
                        width={50}
                        height={60}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />


                    </ContentLoader>
                    <span>FOLLOWERS</span>
                </div>
                <div className="rt-count rt-followingmodal-btn">
                    <ContentLoader
                        speed={2}
                        width={50}
                        height={60}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />


                    </ContentLoader>
                    <span>FOLLOWING</span>
                </div>
            </div>
            <div className="rt-trainingsessions">
                <div className="rt-title">
                    <h2>TRAINING SESSIONS</h2>
                </div>
                <div className="rt-courses">
                    <ContentLoader
                        speed={2}
                        width={1500}
                        height={320}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="16" ry="16" width="250" height="250" />

                        <rect x="260" y="0" rx="16" ry="16" width="250" height="250" />
                        <rect x="520" y="0" rx="16" ry="16" width="250" height="250" />
                        <rect x="780" y="0" rx="16" ry="16" width="250" height="250" />

                    </ContentLoader>
                </div>
            </div>
        </div>
    );
};

export default MemberProfileLoader;
