import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { GoalsModal } from '../../components/modals'
import { CourseVideoComponent } from '../../components/course-video';
import { data } from './data'
import './session-details.scss';
import {sessionService} from "../../services/sessions.service";
import SessionDetailsLoader from "./session-detail-loader";
import {useAlert} from "react-alert";

interface IProps {
    data: any;
}

export const SessionDetails: React.FC<IProps> = (props) => {

    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState({
        name:'',
        category:'',
        description:'',
        second_subtitle:'',
        subtitle:'',
        session_duration:'',
        full_image : '',
        drills: []
    });
    let params : any = useParams();
    let history : any = useHistory();
    let customAlert = useAlert();

    useEffect(() => {
        sessionService.sessionDetail(params.id).then(({data} : any) => {
            console.log(data)
            setSession(data.data)
            setLoading(false)
        });
    }, []);


    console.log(props)
    return (
        <Layout>
            {loading ? <SessionDetailsLoader /> : (
            <div className="rt-content rt-course-details">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to={''} onClick={e => {e.preventDefault(); history.goBack();}} className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>{session.category}</h2>
                    </div>
                </div>
                <div className="rt-coursecontentarea">
                    <div className="rt-banner" style={{background:`url(${session.full_image}) no-repeat`, backgroundSize:'cover'}}>
                        <div className="rt-bannercontent">
                            <div className="rt-bannercontent-holder">
                                <span className="rt-time">
                                    <i className="icon-clock"></i>
                                    {session.session_duration} m
                                </span>
                                <h2>{session.name}</h2>
                                <div className="rt-description">
                                    <p>{session.description}</p>
                                </div>
                                <ul className="rt-courseinfolist">
                                    <li><i className="icon-football"></i> {session.subtitle}</li>
                                    <li><i className="icon-gol"></i> {session.second_subtitle}</li>
                                </ul>
                            </div>
                            <div className="rt-rightbox">
                                <h3>FINISHED ALL DRILLS?</h3>
                                <button type="button" className="rt-btn rt-statsbtn" onClick={() => setModalShow(true)}>INPUT YOUR STATS HERE</button>
                                { modalShow && < GoalsModal show={modalShow} session={session} customAlert={customAlert} onHide={() => setModalShow(false)} />}
                            </div>
                        </div>
                    </div>
                    <div className="rt-coursevideos">
                        {session.drills.map((d: any) => (
                            <CourseVideoComponent
                                data={d}
                            />
                        ))}
                    </div>
                </div>
            </div>
            )}
        </Layout>
    );
};