import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class OrganizeTrainingService extends HttpService {
    private readonly prefix: string = '';
    constructor() {
        super();
        HttpService.setToken(authService.authToken())
    }
    /**
     * Dashboard Data Service
     */
    index = (selectedDate = '') => this.get(`${this.prefix+APIEndpoints.ORGANIZE_TRAINING_INDEX}?date=${selectedDate}`);

    addSession = (data:any) => this.post(`${this.prefix+APIEndpoints.ADD_TRAINING_SESSION}`,data);

    deleteSession = (data:any) => this.post(`${this.prefix+APIEndpoints.DELETE_TRAINING_SESSION}`,data);


}

export const organizeTrainingService = new OrganizeTrainingService();
