import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class SessionService extends HttpService {
    private readonly prefix: string = '';
    constructor() {
        super();
        HttpService.setToken(authService.authToken())
    }
    /**
     * Dashboard Data Service
     */
    categories = () => this.get(`${this.prefix+APIEndpoints.SESSION_CATEGORIES}`);

    bmsuCategories = () => this.get(`${this.prefix+APIEndpoints.BMSU_CATEGORIES}`);

    categorySessions = (id : number) => this.get(`${this.prefix+'category/'+id+'/sessions'}`);

    categoryBmsu = (id : number) => this.get(`${this.prefix+'category/'+id+'/bmsu'}`);

    sessionDetail = (id : number) => this.get(`${this.prefix+'session/'+id+'/detail'}`);

    drillDetail = (id : number) => this.get(`${this.prefix+'drill/'+id+'/detail'}`);

    submitStats = (data : any) => this.post(`${this.prefix+APIEndpoints.SUBMIT_STATS}`,data)

    shareStats = (data : any) => this.post(`${this.prefix+APIEndpoints.SHARE_STATS}`,data)

}

export const sessionService = new SessionService();
