import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class DashboardService extends HttpService {
    private readonly prefix: string = '';
    constructor() {
        super();
        HttpService.setToken(authService.authToken())
    }
    /**
     * Dashboard Data Service
     */
    dashboard = () => this.get(`${this.prefix}${APIEndpoints.DASHBOARD}`);


}

export const dashboardService = new DashboardService();
