import React from 'react';
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {CourseVideoComponent, GoalsModal} from "../../components";

const SessionDetailsLoader = (props:any) => {
    return (
        <div className="rt-content rt-course-details">
            <div className="rt-pageheading">
                <div className="rt-title">
                    <Link to={''} onClick={e => e.preventDefault()} className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                    <h2>Loading...</h2>
                </div>
            </div>
            <div className="rt-coursecontentarea">

                <div className="rt-banner" >
                    <div className="rt-bannercontent">
                        <div className="rt-bannercontent-holder">
                                <span className="rt-time">
                                    <i className="icon-clock"></i>
                                      <ContentLoader
                                          speed={2}
                                          height={25}
                                          width={25}
                                          backgroundColor="#f3f3f3"
                                          foregroundColor="#effeb4"
                                          {...props}
                                      >
                                <rect x="0" y="0" rx="8" ry="8" width="25" height="25" />

                            </ContentLoader>
                                </span>
                            <ContentLoader
                                speed={2}
                                height={100}
                                width={500}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="300" height="35" />
                                <rect x="0" y="50" rx="8" ry="8" width="500" height="35" />

                            </ContentLoader>
                            <ul className="rt-courseinfolist">
                                <li><i className="icon-football"></i>
                                    <ContentLoader
                                        speed={2}
                                        height={25}
                                        width={200}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#effeb4"
                                        {...props}
                                    >
                                        <rect x="0" y="0" rx="8" ry="8" width="100" height="20" />

                                    </ContentLoader>
                                </li>
                                <li><i className="icon-gol"></i>
                                    <ContentLoader
                                        speed={2}
                                        height={25}
                                        width={200}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#effeb4"
                                        {...props}
                                    >
                                        <rect x="0" y="0" rx="8" ry="8" width="100" height="20" />

                                    </ContentLoader>
                                </li>
                            </ul>
                        </div>
                        <div className="rt-rightbox">
                            <h3>FINISHED ALL DRILLS?</h3>
                            <ContentLoader
                                speed={2}
                                height={60}
                                width={220}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="220" height="40" />

                            </ContentLoader>
                        </div>
                    </div>
                </div>

                <div className="rt-coursevideos">
                    <div className="rt-videocard">
                        <div className="rt-themecardbox">
                            <ContentLoader
                                speed={2}
                                width={500}
                                height={100}
                                style={{marginTop:'-0px'}}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="16" ry="16" width="100" height="100" />
                                <rect x="110" y="0" rx="16" ry="16" width="350" height="40" />
                                <rect x="110" y="50" rx="16" ry="16" width="350" height="50" />

                            </ContentLoader>

                        </div>
                    </div>
                    <div className="rt-videocard">
                        <div className="rt-themecardbox">
                            <ContentLoader
                                speed={2}
                                width={500}
                                height={100}
                                style={{marginTop:'-0px'}}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="16" ry="16" width="100" height="100" />
                                <rect x="110" y="0" rx="16" ry="16" width="350" height="40" />
                                <rect x="110" y="50" rx="16" ry="16" width="350" height="50" />

                            </ContentLoader>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SessionDetailsLoader;
