import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class LeaderboardService extends HttpService {
    private readonly prefix: string = '';
    constructor() {
        super();
        HttpService.setToken(authService.authToken())
    }
    /**
     * Dashboard Data Service
     */
    monthly = (page = 1) => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_MONTHLY}?page=${page}`);

    challenges = (page = 1) => this.get(`${this.prefix}${APIEndpoints.CHALLENGES}`);

    teamMates = (page=1) => this.get(`${this.prefix}${APIEndpoints.SOCIAL_UPDATES}?page=${page}`);

    challengeDetail = (id : number) => this.get(`${this.prefix}v2/user/joined/challange/${id}/detail`);

    joinChallenge = (id : number) => this.get(`${this.prefix}v2/user/join/${id}/challange`);


    allTime = (page = 1) => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_ALL}?page=${page}`);


    achievementsProgress = (id : number) => this.get(`${this.prefix}v2/user/${id}/achievements`);



}

export const leaderboardService = new LeaderboardService();
