import React from 'react';
import logo from './logo.svg'
import './App.scss';
// @ts-ignore
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic'
// optional configuration
import MainRoutes from './routes/mainRoutes';

function App() {
    const options = {
        // you can also just use 'bottom center'
        position: positions.TOP_RIGHT,
        timeout: 5000,
        offset: '30px',
        // you can also just use 'scale'
        transition: transitions.SCALE
    }

    return (
        <AlertProvider template={AlertTemplate} {...options}>

            <div className="App">
                <MainRoutes />
            </div>
        </AlertProvider>
    );
}

export default App;
