import React, {Component, FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import './create-password.scss';
import {authService} from "../../../services/auth.service";

export const CreatePage = () => {
    const [formData, setFormData] = useState({
        token : '',
        email:'',
        password : '',
        passwordConfirm : ''
    });

    let history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('email') != undefined){
            setFormData({...formData,email: String(localStorage.getItem('email'))})
        }
    }, []);

    let updatePassword = (e: FormEvent) => {
        e.preventDefault();
        authService.resetPassword(formData).then(({data} : any) => {
            if (data.status_code == 200){
                sessionStorage.setItem('success',data.message);
                history.push(routes.login);
            }
        })

    }
    return (
        <div className="rt-createpasswordholder">
            <div className="rt-split rt-leftsite">
                <figure className="rt-signupimg"><img src={images.signupimg} alt="" /></figure>
            </div>
            <div className="rt-split rt-rightsite">
                <div className="rt-flexcontainer">
                    <div className="rt-formbox">
                        <form className="rt-formtheme rt-forgotpasswordform" onSubmit={updatePassword}>
                            <fieldset>
                                <div className="rt-logobox">
                                    <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                                </div>
                                <div className="form-group">
                                    <h3>CREATE NEW PASSWORD <span>Your new password must be different from previous used password.</span></h3>
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="pincode" className="form-control" id="pincode-field" required value={formData.token} onChange={e => setFormData({...formData,token:e.target.value})} placeholder="PIN CODE" />
                                        <label htmlFor="pincode-field">PIN CODE</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="newpassword" className="form-control" id="newpassword-field" required value={formData.password} onChange={e => setFormData({...formData,password:e.target.value})} placeholder="NEW PASSWORD" />
                                        <label htmlFor="newpassword-field">NEW PASSWORD</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="confirmpassword" className="form-control" id="confirmpassword-field" required value={formData.passwordConfirm} onChange={e => setFormData({...formData,passwordConfirm:e.target.value})} placeholder="CONFIRM PASSWORD" />
                                        <label htmlFor="confirmpassword-field">CONFIRM PASSWORD</label>
                                    </div>
                                </div>
                                <div className="form-group m-0">
                                    <button className="rt-btn rt-btn-lg p-0" type="submit">CHANGE PASSWORD</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};