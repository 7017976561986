import React, { useState} from 'react';
import { HeaderComponent } from '../header/header.component'
import { SidebarComponent } from '../sidebar/sidebar.component'
import './layout.scss'

interface IProps {
    children: any;
    title?: any;
}


export const Layout: React.FC<IProps> = (props) =>{
    
    const [toggleClass, setToggleClass] = useState<boolean>(false);

    const toggleClassHandler = () => {
        setToggleClass((prev) => !prev);
    };
    
    
    
    return (
        <div className= {toggleClass ? "rt-wrapper rt-closesidebar" : "rt-wrapper"}>
            <HeaderComponent toggleClassHandler={toggleClassHandler} title={props.title}/>
            <SidebarComponent toggleClassHandler={toggleClassHandler}/>
            <main className="rt-main">
                {props.children}
            </main>
        </div>
    );
}