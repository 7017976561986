import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { images } from "../../assets/image";
import { Layout, WonChallengeModalModal } from "../../components";
import "./challenges.scss";
import {leaderboardService} from "../../services/leaderboard.service";
import ChallangesLoader from "./challenges-loader";

interface IProps {
    data: any;
}

export const ChallengesPage: React.FC<IProps> = (props: any) => {

    const [modalShow, setModalShow] = useState(false);
    const [modalChallenge, setModalChallenge] = useState({
        title:''
    });
    const [challenges, setChallenges] = useState([]);
    const [completedChallenges, setCompletedChallenges] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        leaderboardService.challenges().then(({data}:any) => {
            console.log(data)
            setChallenges(data.data.challanges)
            setCompletedChallenges(data.data.completed_challanges)
            setLoading(false)
        })
    }, []);

    let openModal = (challenge:any) => {
        setModalChallenge(challenge);
        setModalShow(true);
        console.log('test')
    }


    return (
        <Layout title="CHALLENGES">
            {loading ? <ChallangesLoader /> : (
                <div className="rt-content rt-challenges">
                    <div className="rt-challengesbox">
                        <div className="rt-title">
                            <h2>ALL CHALLENGES</h2>
                        </div>
                        <ul className="rt-challengeslist">

                            {challenges.length > 0 ? challenges.map((challenge : any) => {
                                return (<li>
                                    <Link to={"/challenges-detail/"+challenge.id}>
                                        <figure className="rt-roundimage">
                                            <img src={challenge.full_image} alt="" />
                                        </figure>
                                        <h3>{challenge.title}</h3>
                                    </Link>
                                </li>)
                            }) : <>No Challenge available</>}
                        </ul>
                    </div>
                    <div className="rt-challengesbox">
                        <div className="rt-title">
                            <h2>WON CHALLENGES</h2>
                        </div>
                        <ul className="rt-challengeslist">
                            {completedChallenges.map((challenge:any) => {
                                return  (<li>
                                    <button type="button" onClick={e => openModal(challenge)}>
                                        <figure className="rt-roundimage">
                                            <img src={challenge.full_image} alt="" />
                                        </figure>
                                        <h3>{challenge.title}</h3>
                                    </button>
                                </li>)
                            })}
                            <WonChallengeModalModal challenge={modalChallenge}  onHide={() => setModalShow(false)} show={modalShow}/>
                        </ul>
                    </div>
                </div>
            )}
        </Layout>
    );
};
