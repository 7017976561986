import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.css";
import ContentLoader from "react-content-loader";

const ProgressLoader = (props:any) => {
    return (
        <div className="rt-content rt-progress">
            <div className="rt-progressbox">
                <div className="rt-title">
                    <h2><ContentLoader
                        speed={2}
                        backgroundColor="#f3f3f3"
                        height={45}
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="16" ry="16" width="200" height="40" />

                    </ContentLoader></h2>
                </div>
                <div className="rt-progresscontent">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={8}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            1480: {
                                slidesPerView: 8,
                            },
                            992: {
                                slidesPerView: 6,
                            },
                            576: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            360: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                            0: {
                                slidesPerView: 3,
                            },
                        }}
                        className="rt-progresslist"
                    >
                        <SwiperSlide>
                            <div className="rt-item">
                                <ContentLoader
                                    speed={2}
                                    width={1000}
                                    height={110}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="110" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="220" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="330" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="440" y="0" rx="50" ry="50" width="100" height="100" />

                                </ContentLoader>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
            <div className="rt-progressbox">
                <div className="rt-title">
                    <h2><ContentLoader
                        speed={2}
                        backgroundColor="#f3f3f3"
                        height={45}
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="16" ry="16" width="200" height="40" />

                    </ContentLoader></h2>
                </div>
                <div className="rt-progresscontent">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={8}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            1480: {
                                slidesPerView: 8,
                            },
                            992: {
                                slidesPerView: 6,
                            },
                            576: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            360: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                            0: {
                                slidesPerView: 3,
                            },
                        }}
                        className="rt-progresslist"
                    >
                        <SwiperSlide>
                            <div className="rt-item">
                                <ContentLoader
                                    speed={2}
                                    width={1000}
                                    height={110}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="110" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="220" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="330" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="440" y="0" rx="50" ry="50" width="100" height="100" />

                                </ContentLoader>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
            <div className="rt-progressbox">
                <div className="rt-title">
                    <h2><ContentLoader
                        speed={2}
                        backgroundColor="#f3f3f3"
                        height={45}
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="0" y="0" rx="16" ry="16" width="200" height="40" />

                    </ContentLoader></h2>
                </div>
                <div className="rt-progresscontent">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={8}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            1480: {
                                slidesPerView: 8,
                            },
                            992: {
                                slidesPerView: 6,
                            },
                            576: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            360: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                            0: {
                                slidesPerView: 3,
                            },
                        }}
                        className="rt-progresslist"
                    >
                        <SwiperSlide>
                            <div className="rt-item">
                                <ContentLoader
                                    speed={2}
                                    width={1000}
                                    height={110}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#effeb4"
                                    {...props}
                                >
                                    <rect x="0" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="110" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="220" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="330" y="0" rx="50" ry="50" width="100" height="100" />
                                    <rect x="440" y="0" rx="50" ry="50" width="100" height="100" />

                                </ContentLoader>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default ProgressLoader;
