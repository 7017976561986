import axios from 'axios'
import { API_ENDPOINT } from '../environment'
import {authService} from "./auth.service";
import {routes} from "../routes/routesConstants";

export class HttpService {
	/**
	 * Set Token On Header
	 * @param token
	 */
	static setToken(token: string): void {

		// @ts-ignore
		axios.defaults.headers['Authorization'] = `Bearer ${token}`;
		console.log('token',token)
	}

	/**
	 * Fetch data from server
	 * @param url Endpoint link
	 * @return Promise
	 */
	protected get = async (url: string, params?: any): Promise<any> => {
		const test = await axios.get(`${API_ENDPOINT}/${url}`, {
			params,
		})

		let res: any = test.data
		if (res.status_code == 301) {
			authService.signout()
		}
		
		return test
	}
	/**
	 * Write data over server
	 * @param url Endpoint link
	 * @param body Data to send over server
	 * @return Promise
	 */
	protected post = async (url: string, body: any, options = {}): Promise<any> => {

		const test = await axios.post(`${API_ENDPOINT}/${url}`, body, {
			...options,
		})
		let res: any = test.data
		if (res.status_code == 301) {
			authService.signout()
		}
		return test
	}

	/**
	 * Delete Data From Server
	 * @param url Endpoint link
	 * @param params Embed as query params
	 * @return Promise
	 */
	protected delete = (url: string, params?: any, data?: any): Promise<any> =>
		axios.delete(`${API_ENDPOINT}/${url}`, { params, data })

	/**
	 * Update data on server
	 * @param url Endpoint link
	 * @param body Data to send over server
	 * @param params Embed as query params
	 * @return Promise
	 */
	protected put = (url: string, body?: any, params?: any): Promise<any> =>
		axios.put(`${API_ENDPOINT}/${url}`, body, {
			...params,
		})

}
