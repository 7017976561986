import React, {useState} from 'react';
import {DeleteGoalModalModal} from "../modals";

const GoalComponent = ({goal,setProfileInfo,profileInfo} : any) => {

    const [modalShow, setModalShow] = useState(false);
    return (
        <div className="rt-goalcard">
            <div className="rt-goalcontent">
                <div className="rt-title">
                    <h3>{goal.title}</h3>
                    <div className="rt-description">
                        <p>{goal.message}</p>
                    </div>
                </div>
                <button type="button" className="rt-deletbtn" onClick={() => setModalShow(true)}><i className="icon-delet"></i></button>
                { modalShow && < DeleteGoalModalModal show={modalShow}  goal={goal} setProfileInfo={setProfileInfo} profileInfo={profileInfo} onHide={() => setModalShow(false)} />}
            </div>
            <div className="rt-datebox">
                <span>Added on {goal.date}</span>
            </div>
        </div>

    );
};

export default GoalComponent;
