import React from 'react';
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {CourseCardComponent} from "../../components";

const CategoryDetailLoader = (props:any) => {
    return (
        <div className="rt-content rt-category-details">
            <div className="rt-pageheading">
                <div className="rt-title">
                    <Link to="/train-categores" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                    <ContentLoader
                        speed={2}
                        height={100}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#effeb4"
                        {...props}
                    >
                        <rect x="20" y="35" rx="8" ry="8" width="150" height="30" />

                    </ContentLoader>
                </div>
            </div>

            <div className="rt-categorycontentarea">
                <div className="rt-banner">
                    <figure className="rt-bannerimg">
                        <figcaption className="rt-bannercontent">
                            <ContentLoader
                                speed={2}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="100" rx="8" ry="8" width="200" height="45" />

                            </ContentLoader>
                        </figcaption>
                    </figure>
                </div>
                <div className="rt-courses">
                    <div className="rt-coursecard">
                        <figure className="rt-courseimg">
                                <figcaption className="rt-course-title">
                                    <ContentLoader
                                        speed={2}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#effeb4"
                                        {...props}
                                    >
                                        <rect x="20" y="50" rx="8" ry="8" width="200" height="45" />

                                    </ContentLoader>
                                </figcaption>
                        </figure>
                        <div className="rt-course-content">
                            <ContentLoader
                                speed={2}
                                height={50}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="230" height="45" />

                            </ContentLoader>
                        </div>
                    </div>
                    <div className="rt-coursecard">
                        <figure className="rt-courseimg">
                                <figcaption className="rt-course-title">
                                    <ContentLoader
                                        speed={2}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#effeb4"
                                        {...props}
                                    >
                                        <rect x="20" y="50" rx="8" ry="8" width="200" height="45" />

                                    </ContentLoader>
                                </figcaption>
                        </figure>
                        <div className="rt-course-content">
                            <ContentLoader
                                speed={2}
                                height={50}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="230" height="45" />

                            </ContentLoader>
                        </div>
                    </div>
                    <div className="rt-coursecard">
                        <figure className="rt-courseimg">
                                <figcaption className="rt-course-title">
                                    <ContentLoader
                                        speed={2}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#effeb4"
                                        {...props}
                                    >
                                        <rect x="20" y="50" rx="8" ry="8" width="200" height="45" />

                                    </ContentLoader>
                                </figcaption>
                        </figure>
                        <div className="rt-course-content">
                            <ContentLoader
                                speed={2}
                                height={50}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="230" height="45" />

                            </ContentLoader>
                        </div>
                    </div>
                    <div className="rt-coursecard">
                        <figure className="rt-courseimg">
                                <figcaption className="rt-course-title">
                                    <ContentLoader
                                        speed={2}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#effeb4"
                                        {...props}
                                    >
                                        <rect x="20" y="50" rx="8" ry="8" width="200" height="45" />

                                    </ContentLoader>
                                </figcaption>
                        </figure>
                        <div className="rt-course-content">
                            <ContentLoader
                                speed={2}
                                height={50}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="230" height="45" />

                            </ContentLoader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryDetailLoader;
