import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.css";

const ProgressBoxComponent = ({d,series}:any) => {
    let completedSessions = d.completed_session_stats.length > 0? d.completed_session_stats[0].completed_sessions : 0;

    let getColorFromName = (name:string) => {
        switch (name){
            case 'Footwork':
                return '#FF0000'
            case 'Control':
                return '#FFA500'
            case 'Dribbling':
                return '#008000'
            case 'Shooting':
                return '#0000FF'
            case 'Passing':
                return '#800080'
            default:
                return '#328fa8'
        }
    }
    return (
        <div className="rt-progressbox">
            <div className="rt-title">
                <h2>{d.name}</h2>
            </div>
            <div className="rt-progresscontent">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={8}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        1480: {
                            slidesPerView: 8,
                        },
                        992: {
                            slidesPerView: 6,
                        },
                        576: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                        360: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                        },
                        0: {
                            slidesPerView: 3,
                        },
                    }}
                    className="rt-progresslist"
                >
                    {series.map((val:any) => {
                        // @ts-ignore
                        return (
                            <SwiperSlide>
                                <div className="rt-item">
                                    <span className={'rt-roundicon'} style={{background:(completedSessions >= val ? getColorFromName(d.name) : '')}}>{val}</span>
                                </div>
                            </SwiperSlide>
                        )
                    })}

                </Swiper>
            </div>
        </div>
    );
};

export default ProgressBoxComponent;
