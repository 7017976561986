import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { images } from "../../assets/image";
import { Layout } from "../../components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./progress.scss";
import {leaderboardService} from "../../services/leaderboard.service";
import {authService} from "../../services/auth.service";
import ProgressBoxComponent from "../../components/progress-box/progress-box-component";
import ProgressLoader from "./progress-loader";

interface IProps {
  data: any;
}

export const ProgressPage: React.FC<IProps> = (props: any) => {

  const [progress, setProgress] = useState([]);
  const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
    leaderboardService.achievementsProgress(authService.userId()).then(({data} : any) => {
        if (data.status_code == 200) {
            setProgress(data.data.categories);
            setSeries(data.data.series);
            setLoading(false)
        }
    })
  }, []);

  return (
      <Layout title="PROGRESS">
          {loading ? <ProgressLoader/> :
              <div className="rt-content rt-progress">
                  {progress.map((d: any) => <ProgressBoxComponent d={d} series={series}/>)}
              </div>
          }
      </Layout>
  );
};
