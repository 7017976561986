import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { FindMemberModal, FollowingModal, AddGoalModal, DeleteGoalModalModal } from '../../components';
import './profile.scss';
import {authService} from "../../services/auth.service";
import {userProfileService} from "../../services/profile.service";
import {userInfo} from "os";
import GoalComponent from "../../components/profile/goal.component";
import TrophyComponent from "../../components/profile/trophy.component";
import ProgressLoader from "../progress/progress-loader";
import ProfileLoader from "./profile-loader";

export const ProfilePage = () => {

    const [profileInfo, setProfileInfo] = useState({
        touches: 0,
        passes: 0,
        shots: 0,
        total_sessions: 0,
        completed_stats: [],
        achievements: [],
        goals: [],
        user : {
            followers:0,
            following:0,
        }
    });
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);
    const [followingCount, setFollowingCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showFollower, setShowFollower] = useState(false);

    useEffect(() => {
        userProfileService.profileInfo(authService.userId()).then(({data}) => {
            if (data.status_code == 200){
                setProfileInfo(data.data)
                setFollowingCount(data.data.user.following)
                setLoading(false)
            }
        });
    }, []);

    let showFollowing = () => {
        setShowFollower(false)
        setModalShow1(true)
    }

    let showFollowers = () => {
        setShowFollower(true)
        setModalShow1(true)
    }


    return (
        <Layout title="Profile">
            {loading? <ProfileLoader /> :
                <div className="rt-content rt-profile">
                <div className="rt-profile-inforamation">
                    
                    <div className='rt-imgwithbadge'>
                        <figure className="rt-roundimage rt-profileimage rt-badge">
                            <img src={authService.userInfo().avatar} alt="" />
                            {authService.userInfo().is_verified == 1 ? (<span className="rt-roundicon">
                            <i className="icon-tick"></i>
                        </span>) : ''}
                        </figure>
                    </div>
                    <div className="rt-titlebox">
                        <h2>{authService.userInfo().name}</h2>
                        <span>{authService.userInfo().position}</span>
                        <span>{authService.userInfo().formated_address}</span>
                    </div>
                    <div className="rt-profileactions">
                        <button type="button" className="rt-btn rt-black-btn" onClick={() => setModalShow(true)}><i className="icon-search"></i><span>FIND MEMBERS</span></button>
                        { modalShow && < FindMemberModal followingCount={followingCount} setFollowingCount={setFollowingCount} show={modalShow} onHide={() => setModalShow(false)} />}
                        <Link to="/edit-profile" className="rt-btn rt-black-btn"><i className="icon-edit"></i><span>EDIT PROFILE</span></Link>
                        <Link to="/settings" className="rt-btn rt-black-btn rt-settingbtn"><i className="icon-setting"></i></Link>
                    </div>
                </div>
                <div className="rt-counterbox">
                    <div className="rt-count">
                        <h3>{profileInfo.total_sessions}</h3>
                        <span>SESSIONS</span>
                    </div>
                    <div className="rt-count rt-followingmodal-btn" onClick={() => showFollowers()}>
                        <h3>{profileInfo.user.followers == null ? 0 : profileInfo.user.followers}</h3>
                        <span>FOLLOWERS</span>
                    </div>
                    <div className="rt-count rt-followingmodal-btn" onClick={() => showFollowing()}>
                        <h3>{profileInfo.user.following == null ? 0 : followingCount}</h3>
                        <span>FOLLOWING</span>
                    </div>
                    { modalShow1 && < FollowingModal show={modalShow1} followingCount={followingCount} setFollowingCount={setFollowingCount} showFollower={showFollower} onHide={() => setModalShow1(false)} />}
                </div>
                <div className="rt-trainingandtrophies">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="rt-trainingresulat-area">
                                <div className="rt-title">
                                    <h2>TRAINING SESSIONS ({profileInfo.total_sessions})</h2>
                                </div>
                                <div className="rt-trainingresulat">
                                    <ul className="rt-traininglist">
                                        {profileInfo.completed_stats.map((stat : any) => {
                                            return (
                                                <li>
                                                    <span>{stat.category_name}</span>
                                                    <span>{stat.completed_sessions}</span>
                                                </li>)
                                        })}
                                    </ul>
                                    <div className="rt-resultbox">
                                        <div className="rt-resultcount">
                                            <strong>{profileInfo.touches }</strong>
                                            <span>TOUCHES</span>
                                        </div>
                                        <div className="rt-resultcount">
                                            <strong>{profileInfo.shots}</strong>
                                            <span>SHOTS</span>
                                        </div>
                                        <div className="rt-resultcount">
                                            <strong>{profileInfo.passes}</strong>
                                            <span>PASSES</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="rt-trophiesarea">
                                <div className="rt-title">
                                    <h2>TROPHIES</h2>
                                </div>
                                <div className="rt-trophies">
                                    <ul className="rt-trophilist">
                                        {profileInfo.achievements.map((achievement) => {
                                            return <TrophyComponent trophy={achievement} />
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rt-goalsarea">
                    <div className="rt-title">
                        <h2>GOALS</h2>
                        <button type="button" className="rt-btn rt-border-btn" onClick={() => setModalShow2(true)}><i className="icon-pluse"></i><span>add</span></button>
                        { modalShow2 && < AddGoalModal show={modalShow2} setProfileInfo={setProfileInfo} profileInfo={profileInfo} onHide={() => setModalShow2(false)} />}
                    </div>
                    {profileInfo.goals.map((goal:any) => {
                        return <GoalComponent goal={goal} setProfileInfo={setProfileInfo} profileInfo={profileInfo}/>
                    })}
                </div>
            </div>
            }
        </Layout>
    );
};