import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { GoalsModal } from '../../components/modals'
import { CourseVideoComponent } from '../../components/course-video';
import { data } from './data'
import './course-details.scss';

interface IProps {
    data: any;
}

export const CourseDetails: React.FC<IProps> = (props) => {

    const [modalShow, setModalShow] = useState(false);

    console.log(props)
    return (
        <Layout>
            <div className="rt-content rt-course-details">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to="/category-details" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>TRAIN LIKE A PRO</h2>
                    </div>
                </div>
                <div className="rt-coursecontentarea">
                    <div className="rt-banner">
                        <div className="rt-bannercontent">
                            <div className="rt-bannercontent-holder">
                                <span className="rt-time">
                                    <i className="icon-clock"></i>
                                    12 m
                                </span>
                                <h2>TRAIN LIKE A PRO</h2>
                                <div className="rt-description">
                                    <p>A great passing session featuring World Cup Winners Allie Long and Alex Morgan.<br/> Focus on solid passing and fluid, progressive touches</p>
                                </div>
                                <ul className="rt-courseinfolist">
                                    <li><i className="icon-football"></i> 1920 touches</li>
                                    <li><i className="icon-gol"></i> 1 BALL 10 CONES 1 BUDDY</li>
                                </ul>
                            </div>
                            <div className="rt-rightbox">
                                <h3>FINISHED ALL DRILLS?</h3>
                                <button type="button" className="rt-btn rt-statsbtn" onClick={() => setModalShow(true)}>INPUT YOUR STATS HERE</button>
                                { modalShow && < GoalsModal show={modalShow} onHide={() => setModalShow(false)} />}
                            </div>
                        </div>
                    </div>
                    <div className="rt-coursevideos">
                        {data.map((d: any) => (
                            <CourseVideoComponent
                                data={d}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};