import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";

interface AuthenticateParams {
    token: any;
    user: any;
}

class AuthService extends HttpService {
    private readonly prefix: string = '';

    /**
     * Basic Authenticate User
     * @param data
     */
    login = (data : {}) => this.post(`${this.prefix}${APIEndpoints.LOGIN}`, data);

    getPlans = () => this.get(`${this.prefix}${APIEndpoints.PLANS}`);


    privatePost = ({url, data}: { url: string, data: any }) => {
        HttpService.setToken(this.authToken());

        return this.post(`${this.prefix}${url}`, data);
    }

    privateGet = ({url, query}: { url: string, query: string }) => {
        HttpService.setToken(this.authToken());

        return this.get(`${this.prefix}${url}?${query}`);
    }

    /**
     * Authenticate User
     * @param data
     */
    socialLogin = (data: {}): Promise<any> => this.post(`${this.prefix}/social-login`, data);

    /**
     * Resigter User
     * @param data
     */
    signup = (data: any): Promise<any> => this.post(`${this.prefix}${APIEndpoints.SIGNUP}`, data);

    purchaseSubscription = (data: any): Promise<any> => this.post(`${this.prefix}${APIEndpoints.PURCHASE_SUBSCRIPTION}`, data);

    setToken = (token:any) : Promise<any> => this.setToken(token);

    signupClub = (data: any): Promise<any> => this.post(`${this.prefix}${APIEndpoints.SIGNUP_CLUB}`, data);

    signupPaypalReturn = (data: any): Promise<any> => this.post(`${this.prefix}${APIEndpoints.SIGNUP_PAYPAL_RETURN}`, data);

    /**
     * Forgot Password
     * @param data
     */
    forgotPassword = (data: {}): Promise<any> => this.post(`${this.prefix}${APIEndpoints.FORGET_PASSWORD}`, data);

    /**
     * Forgot Password
     * @param data
     */
    verifyPinCode = (data: {}): Promise<any> => this.post(`${this.prefix}/verify-code`, data);

    /**
     * Forgot Password
     * @param data
     */
    resetPassword = (data: {}): Promise<any> => this.post(`${this.prefix}${APIEndpoints.PASSWORD_UPDATE}`, data);

    authenticate = async ({token, user}: AuthenticateParams) => {
        sessionStorage.setItem('isAuthenticated',String(true));
        await sessionStorage.setItem('token',token);
        sessionStorage.setItem("user",JSON.stringify(user))
        if(user.role_id === 2){
            sessionStorage.setItem("isUser",String(true))
        }else if(user.role_id === 3){
            sessionStorage.setItem("isAdmin",String(true));
        }
    }

    setPaypalUserId = async (id:any) => {
        localStorage.setItem('paypalAuthUserId',id);
    }

    getPaypalUserId = async () => {
        return localStorage.getItem('paypalAuthUserId');
    }

    setUpdatedUserInfo = async (user : any) => {
        sessionStorage.setItem("user",JSON.stringify(user))
    }

    isAuthenticated = () => {
        return (sessionStorage.getItem('isAuthenticated') != undefined && sessionStorage.getItem('isAuthenticated') == 'true')

    }

    username = () => {
        let user = JSON.parse(<string>sessionStorage.getItem("user"))

        return user.name;
    }

    userInfo = () => {
        let user = JSON.parse(<string>sessionStorage.getItem("user"))

        return user;
    }

    userId = () => {
        let user = JSON.parse(<string>sessionStorage.getItem("user"))

        return user.id;
    }

    isTeacher = () => {
        return (sessionStorage.getItem("isTeacher") != undefined && sessionStorage.getItem("isTeacher") === 'true')

    }

    isStudent = () => {
        return (sessionStorage.getItem("isStudent") != undefined && sessionStorage.getItem("isStudent") === 'true')

    }

    authToken = () => {
        return sessionStorage.getItem('token') ?? ''
    }

    signout = () => {
        sessionStorage.clear();
        window.location.href = '/';
    }

}

export const authService = new AuthService();
