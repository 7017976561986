import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import { images } from "../../assets/image";
import {authService} from "../../services/auth.service";


export const WonChallengeModalModal = (props:any) => {


    return(
        <Modal show={props.show} className="rt-thememodal rt-wonchallengemodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-wonchallengemodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-wonchallengecontent">
                        <div className="rt-modaltitle">
                            <h3>CONGRATS! <br/>{authService.userInfo().name}</h3>
                            <div className="rt-description">
                                <p>You have completed {props.challenge.session_required} sessions</p>
                            </div>
                        </div>
                        <div className="rt-badgebox">
                            <figure className="rt-roundimage">
                                <img src={props.challenge.full_image} alt="" />
                            </figure>
                            <h4>{props.challenge.title}</h4>
                        </div>
                        <h3>COMPLETED CHALLENGE</h3>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
