import React from 'react';
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {CourseVideoComponent, GoalsModal} from "../../components";

const VideoDetailsLoader = (props:any) => {
    return (
        <div className="rt-content rt-course-details">
            <div className="rt-pageheading">
                <div className="rt-title">
                    <Link to={''} onClick={e => e.preventDefault()} className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                    <h2>Loading...</h2>
                </div>
            </div>
            <div className="rt-videocontentarea">

                <div className="rt-banner">
                    <div className="rt-videobox" style={{height:'300px'}}>
                    </div>
                    <div className="rt-bannercontent">
                        <ContentLoader
                            speed={2}
                            height={90}
                            width={500}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#effeb4"
                            {...props}
                        >
                            <rect x="0" y="0" rx="8" ry="8" width="200" height="40" />
                            <rect x="0" y="45" rx="8" ry="8" width="450" height="40" />
                            {/*<rect x="0" y="0" rx="16" ry="16" width="100" height="100" />*/}


                        </ContentLoader>
                    </div>
                </div>
                <div className="rt-coursevideos">
                    <div className="rt-videocard">
                        <div className="rt-themecardbox">
                            <ContentLoader
                                speed={2}
                                width={500}
                                height={100}
                                style={{marginTop:'-0px'}}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="16" ry="16" width="100" height="100" />
                                <rect x="110" y="0" rx="16" ry="16" width="350" height="40" />
                                <rect x="110" y="50" rx="16" ry="16" width="350" height="50" />

                            </ContentLoader>

                        </div>
                    </div>
                    <div className="rt-videocard">
                        <div className="rt-themecardbox">
                            <ContentLoader
                                speed={2}
                                width={500}
                                height={100}
                                style={{marginTop:'-0px'}}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="16" ry="16" width="100" height="100" />
                                <rect x="110" y="0" rx="16" ry="16" width="350" height="40" />
                                <rect x="110" y="50" rx="16" ry="16" width="350" height="50" />

                            </ContentLoader>

                        </div>
                    </div>
                    <div className="rt-videocard">
                        <div className="rt-themecardbox">
                            <ContentLoader
                                speed={2}
                                width={500}
                                height={100}
                                style={{marginTop:'-0px'}}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="16" ry="16" width="100" height="100" />
                                <rect x="110" y="0" rx="16" ry="16" width="350" height="40" />
                                <rect x="110" y="50" rx="16" ry="16" width="350" height="50" />

                            </ContentLoader>

                        </div>
                    </div>
                    <div className="rt-videocard">
                        <div className="rt-themecardbox">
                            <ContentLoader
                                speed={2}
                                width={500}
                                height={100}
                                style={{marginTop:'-0px'}}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#effeb4"
                                {...props}
                            >
                                <rect x="0" y="0" rx="16" ry="16" width="100" height="100" />
                                <rect x="110" y="0" rx="16" ry="16" width="350" height="40" />
                                <rect x="110" y="50" rx="16" ry="16" width="350" height="50" />

                            </ContentLoader>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default VideoDetailsLoader;
