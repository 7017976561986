import React, { useEffect, useState } from "react";
import { images } from "../../assets/image";
import {Link, useHistory} from "react-router-dom";
import './course-video.scss'
import {Simulate} from "react-dom/test-utils";

interface IProps {
    data: any;
    playing?: any;
    setDrillId?:any;
    setLoading?:any;
}

export const CourseVideoComponent: React.FC<IProps> = (props:any) => {

    let history = useHistory();

    let changeDrill = (e : any) => {
        e.preventDefault();
        if (props.setLoading != undefined) {
            props.setLoading(true)
        }
        if (props.setDrillId){
            props.setDrillId(props.data.id);
        }
        history.push('/drill/'+props.data.id+'/detail');
    }
    console.log(props.data)
    return (

        <div className="rt-videocard">
            <div className="rt-themecardbox">
                <figure className="rt-videoimg">
                    <Link to={'/drill/'+props.data.id+'/detail'} onClick={e => changeDrill(e)}>
                        <img src={props.data.full_image} alt="" />
                        <span className="rt-videoicon">
                            <i className="icon-video"></i>
                        </span>
                    </Link>
                </figure>
                <div className="rt-themecontent">
                    <h4><Link to={'/drill/'+props.data.id+'/detail'}>{props.data.title}</Link> {props.playing?'(Playing)' : '' }</h4>
                    <p>{props.data.description}</p>
                </div>
            </div>
        </div>

    )
}