import React from 'react';
import ContentLoader from "react-content-loader";

const AddedSessionLoader = (props:any) => {
    return (
        <ContentLoader
            speed={2}
            width={1561}
            height={1000}
            style={{marginTop:'-120px'}}
            viewBox="0 0 1267 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#effeb4"
            {...props}
        >
            <rect x="0" y="0" rx="8" ry="8" width="50" height="50" />
            <rect x="60" y="10" rx="8" ry="8" width="200" height="30" />
            <rect x="270" y="10" rx="8" ry="8" width="30" height="30" />

            <rect x="0" y="65" rx="8" ry="8" width="50" height="50" />
            <rect x="60" y="75" rx="8" ry="8" width="200" height="30" />
            <rect x="270" y="75" rx="8" ry="8" width="30" height="30" />

            <rect x="0" y="130" rx="8" ry="8" width="50" height="50" />
            <rect x="60" y="150" rx="8" ry="8" width="200" height="30" />
            <rect x="270" y="150" rx="8" ry="8" width="30" height="30" />
        </ContentLoader>
    );
};

export default AddedSessionLoader;
