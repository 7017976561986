import React, {Component, FormEvent, useEffect, useState} from 'react';
import { routes } from '../../../routes/routesConstants'
import {Link, useHistory} from 'react-router-dom';
import './signup.scss';
import {authService} from "../../../services/auth.service";
import {APP_URL, PAYMENT_METHOD} from "../../../environment";
import {sign} from "crypto";
import {useAlert} from "react-alert";

export const SignupClub = () => {

    const [userData, setUserData] = useState({
        firstName : '',
        email : '',
        user_name : '',
        phone : '',
        address : '',
        total_users : '',
        months : '',
    });

    let history = useHistory();
    const alert = useAlert();

    let signUp = (e : FormEvent) : void => {
        e.preventDefault();

        authService.signupClub(userData).then(async ({data}) => {
            console.log(data)
            if (data.status_code == 200){
                alert.success('Request Submitted, You will be contacted soon')
                history.push('/')
            }else{
                alert.error('Something Went Wrong')
            }
        })
    }

    return (
        <div className="rt-signupholder">
            <div className="rt-formbox">
                <form className="rt-formtheme rt-forgotpasswordform" onSubmit={signUp}>
                    <fieldset>
                        {/* <div className="rt-logobox">
                            <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                        </div> */}
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                                <div className="form-group text-center"> 
                                    <h3>ACCOUNT INFORMATION</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group"> 
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" required={true} className="form-control" id="name-field" placeholder="CLUB NAME" value={userData.firstName} onChange={(e:any) => setUserData({...userData,firstName: e.target.value})}/>
                                        <label htmlFor="name-field">CLUB NAME</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="email" name="" required={true} className="form-control" id="coachid-field" placeholder="Club Coach Email ID" value={userData.email} onChange={(e:any) => setUserData({...userData,email: e.target.value})} />
                                        <label htmlFor="coachid-field">Club Coach Email ID</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" required={true} className="form-control" id="coachname-field" placeholder="Club Coach Name" value={userData.user_name} onChange={(e:any) => setUserData({...userData,user_name: e.target.value})} />
                                        <label htmlFor="coachname-field">Club Coach Name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" required={true} className="form-control" id="members-field" placeholder="Total Club Members" value={userData.total_users} onChange={(e:any) => setUserData({...userData,total_users: e.target.value})} />
                                        <label htmlFor="members-field">Total Club Members</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" required={true} className="form-control" id="phone-field" placeholder="PHONE"  value={userData.phone} onChange={(e:any) => setUserData({...userData,phone: e.target.value})}/>
                                        <label htmlFor="phone-field">PHONE</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" required={true} className="form-control" id="subscription-field" placeholder="Subscription Months" value={userData.months} onChange={(e:any) => setUserData({...userData,months: e.target.value})} />
                                        <label htmlFor="subscription-field">Subscription Months</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" required={true} className="form-control" id="address-field" placeholder="Address" value={userData.address} onChange={(e:any) => setUserData({...userData,address: e.target.value})} />
                                        <label htmlFor="address-field">Address</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center">
                                    <span className="rt-alreadyaccount">ALREADY HAVE AN ACCOUNT? <Link to={routes.login}>LOGIN</Link></span>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group m-0">
                                    <button type={"submit"} className="rt-btn rt-btn-lg p-0" >SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};