import React, { useEffect, useState } from "react";
import { images } from "../../assets/image";
import { Link } from "react-router-dom";
import './member-session-card.scss'

interface IProps {
    data: any;
}

export const MemberSessionCardComponent: React.FC<IProps> = (props) => {

    return (
        <Link to={'/session/'+props.data.id+'/detail'}>
            <div className="rt-coursecard">
                <figure className="rt-courseimg">
                    <img src={props.data.full_image} alt="" />
                </figure>
                <div className="rt-course-content">
                    <h3>{props.data.name}</h3>
                    <span className="rt-time"><i className="icon-clock"></i>{props.data.session_duration}m</span>
                </div>
            </div>
        </Link>
    )
}